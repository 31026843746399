import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import UserAvatar from "../components/UserAvatar";
import { useSessionContext } from "../context/SessionContext";
import NavLinkForward from "./NavLinkForward";

/**
 * Account menu that pops over all over elements when the username is clicked.
 */
export function AccountMenu() {
  const session = useSessionContext();
  const user = session.user;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  let displayName = "Please sign in";

  // Configure menu for signed-in user
  if (user) {
    if (user.firstName || user.lastName) {
      displayName = `${user.firstName || ""} ${user.lastName || ""}`;
    } else if (user.netid) {
      displayName = user.netid;
    } else {
      displayName = user.uid;
    }
  }

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <Tooltip title="Your Account" aria-label="Your Account">
        <IconButton
          className="profileButton"
          role="button"
          aria-haspopup="true"
          aria-controls="user-context-menu"
          aria-label="Open profile options"
          onClick={handleClick}
          size="large"
        >
          <UserAvatar user={session.user} />
        </IconButton>
      </Tooltip>
      <Menu
        id="user-context-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        className="uw-userMenu"
      >
        <MenuItem
          onClick={handleClose}
          component={NavLinkForward}
          to="/account"
        >
          <UserAvatar user={session.user} />
          <div>
            <Typography className="uw-userName">{displayName}</Typography>
            {user?.isBetaTester && (
              <Typography className="uw-betaTester">Beta Tester</Typography>
            )}
          </div>
        </MenuItem>
        <Divider aria-hidden="true" />
        <MenuItem
          onClick={handleClose}
          component={NavLinkForward}
          to={user ? "/logout" : "/login"}
        >
          {"Sign " + (user ? "out" : "in")}
        </MenuItem>
      </Menu>
    </>
  );
}

export default AccountMenu;
