import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import {
  ErrorMessage,
  Field,
  FieldProps,
  Form,
  Formik,
  FormikProps,
  getIn,
} from "formik";
import { DateTime } from "luxon";
import {
  isOversightOffice,
  OversightOffice,
} from "../../oversight-offices/types";
import { isPerson, Person } from "../../people/types";
import { isSponsorUnit, SponsorUnit } from "../../sponsor-units/types";
import DatePicker from "./DatePicker";
import FormButtons from "./FormButtons";
import OversightOfficePicker from "./OversightOfficePicker";
import PersonPicker from "./PersonPicker";
import SponsorUnitPicker from "./SponsorUnitPicker";

/**
 * Form Menu Item to be rendered in a select element
 * val: string - value for the item
 * text: string - text that is displayed in the item to the user
 */
export type FormMenuItem = {
  val?: string;
  text?: string;
};

/**
 * Form Schema that takes in all nessesary elements to handle form creation
 * handleSubmit: (obj: T, setSubmitting: (isSubmitting: boolean) => void) => void - function to be called when form is submitted
 * validationSchema: object - YUP validation schema
 * initialValues: T - an object that contains the initial form values
 * groups: Group[] - an optional array of groups to render
 * saveText: string - optional text that is displayed in the save button
 * handleCancel: () => void - optional handle cancel of the form
 */
export type FormSchema<T> = {
  handleSubmit: (
    obj: T,
    setSubmitting: (isSubmitting: boolean) => void
  ) => void;
  validationSchema: object;
  initialValues: T;
  groups: Group[];
  saveText?: string;
  handleCancel?: () => void;
};

/**
 * Field conditional that will conditionally render fields based on the value of another field. This function only checks equality. ex. if field === string then render
 * field: string - name of field to validate against
 * is: string - value of above field required to show field
 */
export type FieldConditional = {
  field: string;
  is: string;
};

/**
* Group that holds fields and is wrapped by the given wrapper element
   name: string - name of group
   prettyName: string - pretty name to be displayed
   fields: Field[] - fields that are children of the wrapper.
   wrapper: React.ReactElement - optional element to wrap fields in. If undefined default to DefaultWrapper
*/
export type Group = {
  name: string;
  prettyName: string;
  fields: Field[];
  wrapper?: (props: WrapperProps) => React.ReactElement;
};

/**
 * Field to be displayed in the form
 *
 * name: string - name of the form field. This is what Formik uses to validate against, and set values.
 *   This must be the same as it appears in your validation schema and object
 * prettyName: string - name to be displayed to the user
 * required: boolean - if the field is required or not.
 * type: string - the type of form field to be displayed
 * ariaLabel: string - aria label to be attached to the form field
 * items: FormMenuItem[] - optional items to be displayed with a select field type
 * conditional: FieldConditional - optional conditional that will decide to render the field.
 * fieldProps: object - optional object of MUI fieldProps
 * inputProps: object - optional object of props passed to the underlying input element
 */
export type Field = {
  name: string;
  prettyName: string;
  required: boolean;
  type:
    | "text"
    | "personSelect"
    | "sponsorUnitSelect"
    | "oversightOfficeSelect"
    | "date"
    | "select"
    | "number"
    | "yesNo";
  ariaLabel: string;
  items?: FormMenuItem[];
  conditional?: FieldConditional;
  fieldProps?: object;
  inputProps?: object;
  helperText?: string;
};

/**
 * Form Generator Props
 */
type FormGeneratorProps<T> = {
  schema: FormSchema<T>;
};

/**
 * Props for RenderGroup function
 */
type RenderGroupProps = {
  group: Group;
  values: object;
};

/**
 * Props for the group wrapper
 */
export type WrapperProps = {
  children: React.ReactNode;
  prettyName: string;
  name: string;
};

/**
 * Default Wrapper if no wrapper is given.
 */
export const DefaultWrapper = (props: WrapperProps) => (
  <Grid item xs={12}>
    {props.children}
  </Grid>
);

/**
 * Main Form Generator Class
 */
function FormGenerator<T extends object>(props: FormGeneratorProps<T>) {
  const {
    initialValues,
    validationSchema,
    handleSubmit,
    saveText,
    handleCancel,
    groups,
  } = props.schema;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={(values, actions) => {
        handleSubmit(values, actions.setSubmitting);
      }}
    >
      {(props: FormikProps<T>) => {
        const { isSubmitting, isValid, values } = props;

        return (
          <Form
            style={{ width: "100%" }}
            noValidate
            autoComplete="off"
            translate="yes"
            className="uw-form"
          >
            <Grid container spacing={3}>
              {groups.map((group: Group) => {
                return (
                  <RenderGroup key={group.name} group={group} values={values} />
                );
              })}
            </Grid>
            <FormButtons
              isSubmitting={isSubmitting}
              saveText={saveText}
              saveDisabled={!isValid}
              handleCancel={handleCancel}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

function RenderGroup(props: RenderGroupProps) {
  const group = props.group;
  const values = props.values;
  const Wrapper = group.wrapper || DefaultWrapper;
  return (
    <Wrapper prettyName={props.group.prettyName} name={props.group.name}>
      {group.fields.map((formField: Field) => {
        if (
          (formField.conditional &&
            getIn(values, formField.conditional.field) ===
              formField.conditional.is) ||
          !formField.conditional
        ) {
          return (
            <div key={formField.name}>
              <Field name={formField.name}>
                {({ field, form, meta }: FieldProps) => {
                  return renderField(formField, {
                    field,
                    form,
                    meta,
                  });
                }}
              </Field>
            </div>
          );
        }
      })}
    </Wrapper>
  );
}

function renderField(formField: Field, { field, form, meta }: FieldProps) {
  return (
    <FormControl
      variant="outlined"
      fullWidth={true}
      className="uw-form-input-field"
      error={meta.error !== undefined && meta.touched}
    >
      {fieldType(formField, { field, form, meta })}
      <ErrorMessage
        name={formField.name}
        render={(msg) => (
          <FormHelperText error={true} role="alert">
            {msg}
          </FormHelperText>
        )}
      />
    </FormControl>
  );
}

function fieldType(formField: Field, { field, form, meta }: FieldProps) {
  switch (formField.type) {
    case "personSelect":
      return (
        <PersonPicker
          {...formField.fieldProps}
          aria-label={formField.ariaLabel}
          label={formField.prettyName}
          defaultValue={
            field.value && isPerson(field.value) ? field.value : undefined
          }
          onPersonChange={(value: Person | null) => {
            if (value) {
              form.setFieldValue(field.name, value.id);
            }

            form.setFieldValue(field.name, value);
          }}
        />
      );
    case "sponsorUnitSelect":
      return (
        <SponsorUnitPicker
          aria-label={formField.ariaLabel}
          label={formField.prettyName}
          defaultValue={
            field.value && isSponsorUnit(field.value) ? field.value : undefined
          }
          onSponsorUnitChange={(value: SponsorUnit | null) => {
            form.setFieldValue(field.name, value);
          }}
        />
      );
    case "oversightOfficeSelect":
      return (
        <OversightOfficePicker
          aria-label={formField.ariaLabel}
          label={formField.prettyName}
          defaultValue={
            field.value && isOversightOffice(field.value)
              ? field.value
              : undefined
          }
          onOversightOfficeChange={(value: OversightOffice | null) => {
            form.setFieldValue(field.name, value);
          }}
        />
      );
    case "text":
      return (
        <TextField
          {...field}
          {...formField.fieldProps}
          helperText={formField.helperText}
          InputProps={formField.inputProps}
          variant="outlined"
          value={(field.value as string) || ""}
          label={formField.prettyName}
          required={formField.required}
          id={formField.name}
          error={meta.error !== undefined && meta.touched}
          fullWidth
        />
      );
    case "number":
      return (
        // Instead of using an <input type="number" />, we are using a standard text
        // input, specifying the `numeric` input mode and using a regular expression
        // to provide additional validation. See the following link for additional
        // reading: https://mui.com/material-ui/react-text-field/#type-quot-number-quot
        <TextField
          {...field}
          {...formField.fieldProps}
          helperText={formField.helperText}
          InputProps={formField.inputProps}
          variant="outlined"
          value={(field.value as number) || ""}
          type="text"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          label={formField.prettyName}
          required={formField.required}
          id={formField.name}
          error={meta.error !== undefined && meta.touched}
        />
      );
    case "date":
      return (
        <DatePicker
          name={formField.name}
          variant="outlined"
          defaultDate={field.value as Date}
          onChange={(value: DateTime | null) => {
            form.setFieldValue(field.name, value ? value.toJSDate() : null);
          }}
          fullWidth={true}
          onBlur={field.onBlur}
          ariaLabel={formField.ariaLabel}
          label={getRequiredLabel(formField)}
          error={meta.error !== undefined && meta.touched}
        />
      );
    case "select":
      return (
        <>
          <InputLabel id={formField.name}>
            {getRequiredLabel(formField)}
          </InputLabel>
          <Field
            data-testid={`test-id-${field.name}`}
            as={Select}
            labelId={formField.name}
            label={getRequiredLabel(formField)}
            id={`${formField.name}-select`}
            name={formField.name}
            fullWidth={true}
            value={(field.value as string) || ""}
            error={meta.error !== undefined && meta.touched}
          >
            {renderMenuItems(formField.items)}
          </Field>
        </>
      );
    case "yesNo":
      return (
        <FormControl
          className="uw-staff-form"
          component="fieldset"
          fullWidth={true}
        >
          <FormLabel id={field.name} component="legend">
            {getRequiredLabel(formField)}
          </FormLabel>
          <RadioGroup
            row
            className="uw-radio"
            aria-labelledby={field.name}
            name={field.name}
            value={field.value as boolean}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value === "true";
              form.setFieldValue(field.name, value);
            }}
          >
            <FormControlLabel
              value={true}
              control={<Radio />}
              checked={field.value as boolean}
              label="Yes"
            />
            <FormControlLabel
              value={false}
              checked={!field.value}
              control={<Radio />}
              label="No"
            />
          </RadioGroup>
        </FormControl>
      );
  }

  /**
   * Append "*" to labels if the field is required. This is used for fields
   * that do not have the `required` prop.
   * @param {Field} field
   */
  function getRequiredLabel(field: Field) {
    return field.required ? `${field.prettyName} *` : field.prettyName;
  }

  /**
   * Render menu items for select dropdowns
   * @param {FormMenuItem[]} items - Menu items to render in select.
   */
  function renderMenuItems(items: FormMenuItem[] | undefined) {
    if (!items) {
      console.error("No Form Items Given");
      return;
    }
    return items.map((item: FormMenuItem, index: number) => {
      return (
        <MenuItem key={index} value={item.val}>
          {item.text}
        </MenuItem>
      );
    });
  }
}

export default FormGenerator;
