import { Avatar, ListItem, ListItemAvatar } from "@mui/material";
import { ReactNode } from "react";

/**
 * A basic ListItem that includes an Avatar.
 * @param {ReactNode} avatar An Avatar. Should be a ReactNode from MUI
 * @param {ReactNode} children Children
 */
export const YarsAvatarListItem = ({
  avatar,
  children,
  className,
}: {
  avatar: ReactNode;
  children: ReactNode;
  className?: string;
}) => {
  return (
    <ListItem>
      <ListItemAvatar className={className}>
        <Avatar>{avatar}</Avatar>
      </ListItemAvatar>
      {children}
    </ListItem>
  );
};
