import { Menu } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Container,
  IconButton,
  IconButtonProps,
  List,
  SwipeableDrawer,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { SkipNavContent } from "@reach/skip-nav";
import clsx from "clsx";
import { useEffect, useState } from "react";
import PageHeadings from "../components/PageHeadings";
import { useSessionContext } from "../context/SessionContext";
import { useTitleContext } from "../context/TitleContext";
import AccountMenu from "./AccountMenu";
import defaultNavigationItems from "./defaultNavigationItems";
import ListItemLink from "./ListItemLink";
import NavLinkForward from "./NavLinkForward";
import Routes from "./Routes";

const drawerWidth = 240;

/**
 * A button used for mobile app navigation.
 */
const NavMenuButton: React.FC<IconButtonProps> = ({ children, ...rest }) => {
  return (
    <IconButton className="uw-menu-btn" size="large" {...rest}>
      {children}
    </IconButton>
  );
};

/**
 * Primary component of the application. Renders an app bar, navigation drawer, and the content for the active route.
 */
export const Main = () => {
  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down("md"),
    {
      noSsr: true,
    }
  );
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  const { appName, appNameShort } = useTitleContext();
  const session = useSessionContext();

  useEffect(() => {
    setDrawerOpen(!isMobile);
  }, [isMobile, setDrawerOpen]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) =>
            isMobile && drawerOpen ? 0 : theme.zIndex.drawer + 1,
        }}
        className={clsx("app-header")}
      >
        <Toolbar>
          <NavMenuButton
            aria-label="open navigation menu"
            onClick={() => setDrawerOpen(true)}
            sx={(theme: Theme) => ({
              display: isMobile && !drawerOpen ? "block" : "none",
              mr: theme.spacing(2),
            })}
          >
            <Menu />
          </NavMenuButton>
          <Typography
            variant="h6"
            component="span"
            sx={{ flex: 1, lineHeight: 1.2 }}
          >
            <NavLinkForward to="/" exact={true} className="appNameLink">
              {isMobile ? appNameShort : appName}
            </NavLinkForward>
          </Typography>
          <AccountMenu />
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={(theme: any) => ({
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflow: "hidden",
          ml: isMobile ? 0 : `${drawerWidth}px`,
          pt: "24px",
          px: 0,
          transition: theme.transitions.create("margin", {
            easing:
              drawerOpen && !isMobile
                ? theme.transitions.easing.easeOut
                : theme.transitions.easing.sharp,
            duration:
              drawerOpen && !isMobile
                ? theme.transitions.duration.enteringScreen
                : theme.transitions.duration.leavingScreen,
          }),
        })}
      >
        <SwipeableDrawer
          variant={isMobile ? "temporary" : "permanent"}
          open={drawerOpen}
          onOpen={() => setDrawerOpen(true)}
          onClose={() => setDrawerOpen(false)}
          sx={{
            position: "absolute",
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <Box
            sx={(theme: any) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              py: 0,
              px: "8px",
              ...theme.mixins.toolbar,
            })}
          >
            {isMobile && (
              <IconButton
                onClick={() => setDrawerOpen(false)}
                aria-label="close navigation menu"
              >
                <CloseIcon />
              </IconButton>
            )}
          </Box>
          <Box
            component="nav"
            onClick={() => setDrawerOpen(false)}
            sx={{ overflow: "auto" }}
          >
            <List className="mainNavigationList">
              {defaultNavigationItems.map((item) => {
                const user_permissions = session.user?.permissions || [];
                if (
                  !item.permission ||
                  user_permissions.includes(item.permission)
                ) {
                  return (
                    <ListItemLink
                      primary={item.title}
                      to={item.path}
                      icon={item.icon}
                      key={item.path}
                      exact={item.exact}
                    />
                  );
                }
              })}
            </List>
          </Box>
        </SwipeableDrawer>
        <Container
          maxWidth="lg"
          sx={{
            pt: (theme) => theme.spacing(4),
            overflowY: "scroll",
            flexGrow: 1,
          }}
        >
          <SkipNavContent>
            <PageHeadings />
            <Routes />
          </SkipNavContent>
        </Container>
      </Box>
    </Box>
  );
};

export default Main;
