import { Group, GroupJson } from "../auth/types";
import { INotes } from "../common/types";
import { Person, PersonJson } from "../people/types";
import { Session, SessionJson } from "../sessions/types";
import { SponsorUnit, SponsorUnitJson } from "../sponsor-units/types";

/**
 * A program is an extracurricular activity that hosts youth.
 */
export interface Program extends INotes {
  id: number;
  name: string;
  active?: boolean;
  status: Status;
  description: string;
  slug: string;
  sponsorUnit?: SponsorUnit | number;
  director?: Person | number;
  website?: string;
  participantRegistrationSystem?: string;
  sessions?: Session[];
  groups?: Array<Group>;
  permissions?: string[];
  createdAt?: Date;
  updatedAt?: Date;
  liaisonComments?: string;
}

/** Represents a Program in JSON from the backend API */
export interface ProgramJson extends INotes {
  id: number;
  name: string;
  active?: boolean;
  status: Status;
  description: string;
  slug: string;
  sponsor_unit?: SponsorUnitJson | number;
  director?: PersonJson | number;
  website?: string;
  participant_registration_system?: string;
  session_set?: SessionJson[];
  groups?: Array<GroupJson>;
  permissions?: string[];
  created_at?: string;
  updated_at?: string;
  liaison_comments?: string;
}

// Valid statuses for Programs
export type Status = "draft" | "submitted" | "reviewed" | "denied";
type StatusMap = {
  [K in Status]: string;
};

export const statusTooltip: StatusMap = {
  draft: "Draft programs are not yet ready for review by a Liaison.",
  submitted:
    "Submitted programs are awaiting review by a Liaison. They have not been reviewed or denied yet.",
  reviewed: "Reviewed programs have been approved by a Liaison.",
  denied:
    "Denied programs have been denied for one or more reasons by a Liaison. View the program to see the Liaison's comments.",
};

export function isProgram(
  program: Program | number | INotes
): program is Program {
  const asProgram = program as Program;
  return (
    asProgram instanceof Object &&
    "id" in asProgram &&
    "status" in asProgram &&
    asProgram.id !== undefined
  );
}

export function isProgramJson(
  program: ProgramJson | number
): program is ProgramJson {
  return (program as ProgramJson).id !== undefined;
}
