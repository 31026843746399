import DoneIcon from "@mui/icons-material/Done";
import EmailIcon from "@mui/icons-material/Email";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import PriorityIcon from "@mui/icons-material/PriorityHigh";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { YarsAvatarListItem } from "../../components/list";
import YarsCardHeader from "../../components/YarsCardHeader";
import NavLinkForward from "../../navigation/NavLinkForward";
import { Session, yesNoItems } from "./../types";

const yesNoItemsDisplay = (field: string) =>
  yesNoItems.find(({ val }) => val === field)?.text;

const volunteerSupervisorDisplay = (session: Session) => {
  if (session.hasVolunteers === "y") {
    return session.volunteerSupervisor;
  } else {
    if (session.hasVolunteers) {
      return yesNoItemsDisplay(session.hasVolunteers);
    } else {
      return "Unknown";
    }
  }
};

function StaffCard(props: { session: Session }) {
  const location = useLocation();
  const session = props.session;

  return (
    <Card
      variant="outlined"
      aria-label="Staff details"
      className="uw-card-details"
    >
      <YarsCardHeader title="Staff" />
      <CardContent>
        <List dense={true} className="details-list">
          <ListItem>
            <ListItemAvatar
              className={session.staffCount ? "success" : "warning"}
            >
              <Avatar>
                {session.staffCount ? <GroupsIcon /> : <PriorityIcon />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Staff Count"
              secondary={session.staffCount || "Unknown"}
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar
              className={session.staffYoungestAge ? "success" : "warning"}
            >
              <Avatar>
                {session.staffYoungestAge ? <PersonIcon /> : <PriorityIcon />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Youngest Staff Age"
              secondary={session.staffYoungestAge || "Unknown"}
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar
              className={session.staffBackgroundChecks ? "success" : "warning"}
            >
              <Avatar>
                {session.staffBackgroundChecks ? (
                  <DoneIcon />
                ) : (
                  <PriorityIcon />
                )}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Background Checks"
              secondary={
                session.staffBackgroundChecks ? "Completed" : "Unknown"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar
              className={session.staffRequiredTraining ? "success" : "warning"}
            >
              <Avatar>
                {session.staffRequiredTraining ? (
                  <DoneIcon />
                ) : (
                  <PriorityIcon />
                )}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Required Training"
              secondary={
                session.staffRequiredTraining ? "Completed" : "Unknown"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar
              className={session.staffRequiredTraining ? "success" : "warning"}
            >
              <Avatar>
                {session.staffOrientation ? <DoneIcon /> : <PriorityIcon />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Event With Staff Orientation"
              secondary={session.staffOrientation ? "Yes" : "Unknown"}
            />
          </ListItem>
          <YarsAvatarListItem
            className={session.hasVolunteers ? "success" : "warning"}
            avatar={session.hasVolunteers ? <PersonIcon /> : <PriorityIcon />}
          >
            <ListItemText
              primary="Volunteers"
              secondary={
                session.hasVolunteers
                  ? yesNoItemsDisplay(session.hasVolunteers)
                  : "Unknown"
              }
            />
          </YarsAvatarListItem>
          {(session.hasVolunteers === "y" || session.hasVolunteers === "") && (
            <>
              <YarsAvatarListItem
                className={session.hasVolunteers ? "success" : "warning"}
                avatar={
                  session.volunteerSupervisor ? (
                    <PersonIcon />
                  ) : (
                    <PriorityIcon />
                  )
                }
              >
                <ListItemText
                  primary="Volunteer Supervisor"
                  secondary={volunteerSupervisorDisplay(session)}
                />
              </YarsAvatarListItem>
              <YarsAvatarListItem
                className={session.hasVolunteers ? "success" : "warning"}
                avatar={
                  session.volunteerSupervisorEmail ? (
                    <EmailIcon />
                  ) : (
                    <PriorityIcon />
                  )
                }
              >
                <ListItemText
                  primary="Volunteer Supervisor Email"
                  secondary={session.volunteerSupervisorEmail || "Unknown"}
                />
              </YarsAvatarListItem>
            </>
          )}
        </List>
      </CardContent>
      <CardActions>
        <Box m={2}>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Button
                className="primary-action-btn"
                component={NavLinkForward}
                to={`${location.pathname}/staff`}
                size="medium"
                variant="contained"
              >
                Manage Staff
              </Button>
            </Grid>
          </Grid>
        </Box>
      </CardActions>
    </Card>
  );
}

export default StaffCard;
