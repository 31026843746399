import {
  Card,
  CardContent,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import { date, number, object, string } from "yup";
import { ApiError } from "../api/errors";
import programClient from "../api/programClient";
import sessionClient from "../api/sessionClient";
import { DEFAULT_ERROR_NOTIFICATION } from "../common/constants";
import { Redirect as RedirectType } from "../common/types";
import FormGenerator, {
  Field,
  FormSchema,
  Group,
  WrapperProps,
} from "../components/forms/FormGenerator";
import YarsCardHeader from "../components/YarsCardHeader";
import { useNotificationContext } from "../context/NotificationContext";
import { Program } from "../programs/types";
import {
  custodialCareItems,
  orgTypeItems,
  Session,
  typeItems,
  yesNoItems,
} from "./types";

type SessionFormProps = {
  session?: Session;
};

function SessionForm(props: SessionFormProps) {
  const { state } = useLocation();
  const [session] = useState<Partial<Session>>(
    props.session ||
      (state as Partial<Session>) || {
        name: "",
        maxParticipants: 0,
        runStatus: "Pending",
        typeCode: "",
        custodialCare: "",
      }
  );
  const [program, setProgram] = useState<Program>({} as Program);
  const [redirectObj, setRedirectObj] = useState<RedirectType>({
    redirect: false,
    uri: "",
  });
  const { setNotification } = useNotificationContext();
  const { programSlug } = useParams<{ programSlug: string }>();

  useEffect(() => {
    if (programSlug) {
      programClient
        .getBySlug(programSlug)
        .then((programResponse: Program) => {
          setProgram(programResponse);
        })
        .catch((e: Error) => {
          console.error(`Unable to load program ${program.name}: ${e.message}`);
        });
    }
  }, [programSlug]);

  function handleSubmit(
    session: Partial<Session>,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    if (session.id) {
      sessionClient
        .update(session)
        .then((sessionResponse) => {
          setNotification({
            message: "Session Updated!",
            type: "success",
          });
          setSubmitting(false);
          setRedirectObj({
            redirect: true,
            uri:
              "/programs/" +
              program.slug +
              "/sessions/" +
              sessionResponse.slug +
              "/",
          });
        })
        .catch((e: Error) => {
          if (e instanceof ApiError && e.fieldErrors) {
            const errors = e.fieldErrors;
            for (const [_, errorMessage] of Object.entries(errors)) {
              setNotification({
                message: errorMessage,
                type: "error",
              });
            }
          } else {
            console.error(
              `Unable to update session ${session.id ?? ""}: ${e.message}`
            );
            setNotification({
              message: DEFAULT_ERROR_NOTIFICATION,
              type: "error",
            });
          }
          setSubmitting(false);
        });
    } else {
      session.program = program.id;
      sessionClient
        .create(session)
        .then((response) => {
          setNotification({
            message: "Session Created!",
            type: "success",
          });
          setSubmitting(false);
          setRedirectObj({
            redirect: true,
            uri:
              "/programs/" + program.slug + "/sessions/" + response.slug + "/",
          });
        })
        .catch((e: Error) => {
          console.error("Unable to create session: " + e.message);
          setNotification({
            message: DEFAULT_ERROR_NOTIFICATION,
            type: "error",
          });
          setSubmitting(false);
        });
    }
  }

  const validationSchema = object().shape(
    {
      name: string().trim().required("Session Name is required"),
      maxParticipants: number().required(
        "Maximum number of participants is required"
      ),
      typeCode: string().required(),
      primaryOvernightLocation: string().when("typeCode", {
        is: "res",
        then: string().required(
          'Primary Overnight Location is required when "Residential" type is selected'
        ),
        otherwise: string().nullable(),
      }),
      typeInfo: string().when("typeCode", {
        is: "oth",
        then: string()
          .trim()
          .required('Type Info is required when "Other" type is selected'),
        otherwise: string().nullable(),
      }),
      registeredStudentOrgType: string().when("typeCode", {
        is: "rso",
        then: string().required(
          'Registered Student Organization Type is required when "Registered Student Organization" type is selected'
        ),
        otherwise: string().nullable(),
      }),
      custodialCare: string().required(),
      startDate: date().required("A start date is required"),
      endDate: date().required("An end date is required"),
      formsDue: date().required("Forms Submission Due Date is required"),
      formsClose: date().required("Last Day To Update Date is required"),
      registrationOpen: date().required("Registration Open Date is required"),
      registrationClose: date().required(
        "Registration Deadline Date is required"
      ),
      fee: string().required("Fee amount is Required"),
      deposit: string().required("Deposit number is required"),
      hasVolunteers: string().required("This field is required"),
      volunteerSupervisor: string().when("hasVolunteers", {
        is: "y",
        then: string().required(
          "Volunteer Supervisor is required when a session has volunteers"
        ),
        otherwise: string().nullable(),
      }),
      volunteerSupervisorEmail: string().when("hasVolunteers", {
        is: "y",
        then: string().required(
          "Volunteer Supervisor Email is required when a session has volunteers"
        ),
        otherwise: string().nullable(),
      }),
      glCode: string().nullable(),
    },
    [["startDate", "endDate"]]
  );

  if (redirectObj.redirect) {
    return <Redirect push to={redirectObj.uri} />;
  }

  const fields: Field[] = [
    {
      name: "name",
      prettyName: "Name",
      ariaLabel: "name",
      type: "text",
      required: true,
    },
    {
      name: "description",
      prettyName: "Description",
      ariaLabel: "description",
      type: "text",
      required: false,
      fieldProps: {
        multiline: true,
      },
    },
    {
      name: "maxParticipants",
      prettyName: "What is the maximum number of participants?",
      type: "number",
      ariaLabel: "Maximum number of participants",
      required: true,
    },
    {
      name: "custodialCare",
      prettyName: "Who Takes Custodial Care",
      type: "select",
      required: true,
      ariaLabel: "custodial care",
      items: custodialCareItems,
    },
    {
      name: "director",
      prettyName: "Session Director",
      type: "personSelect",
      ariaLabel: "session director",
      required: false,
    },
    {
      name: "waitlist",
      prettyName: "Is there a waitlist?",
      type: "select",
      required: false,
      ariaLabel: "Waitlist",
      items: yesNoItems,
    },
    {
      name: "hasVolunteers",
      prettyName: "Does this session have volunteers?",
      type: "select",
      required: true,
      ariaLabel: "Does this session have volunteers?",
      items: yesNoItems,
    },
    {
      name: "volunteerSupervisor",
      prettyName: "Volunteer Supervisor",
      type: "text",
      required: true,
      ariaLabel: "Volunteer supervisor",
      conditional: { field: "hasVolunteers", is: "y" },
    },
    {
      name: "volunteerSupervisorEmail",
      prettyName: "Volunteer Supervisor Email",
      type: "text",
      required: true,
      ariaLabel: "Volunteer supervisor email",
      conditional: { field: "hasVolunteers", is: "y" },
    },
  ];

  const typeFields: Field[] = [
    {
      name: "typeCode",
      prettyName: "Type",
      type: "select",
      required: true,
      ariaLabel: "type",
      items: typeItems,
    },
    {
      name: "primaryOvernightLocation",
      prettyName: "Primary Overnight Location",
      type: "text",
      required: true,
      ariaLabel: "primary overnight location",
      conditional: { field: "typeCode", is: "res" },
    },
    {
      name: "typeInfo",
      prettyName: "Type Info",
      type: "text",
      required: true,
      ariaLabel: "type info",
      conditional: { field: "typeCode", is: "oth" },
    },
    {
      name: "registeredStudentOrgType",
      prettyName: "Registered Student Organization Type",
      type: "select",
      required: true,
      ariaLabel: "registered student organization type",
      conditional: { field: "typeCode", is: "rso" },
      items: orgTypeItems,
    },
    {
      name: "primaryLocation",
      prettyName: "Primary Location",
      type: "text",
      ariaLabel: "primary location",
      required: false,
    },
  ];

  const dateFields: Field[] = [
    {
      name: "startDate",
      prettyName: "Start Date",
      type: "date",
      ariaLabel: "start date",
      required: true,
    },
    {
      name: "endDate",
      prettyName: "End Date",
      type: "date",
      ariaLabel: "end date",
      required: true,
    },
    {
      name: "registrationOpen",
      prettyName: "First day to register for session",
      type: "date",
      ariaLabel: "First Day To Register",
      required: true,
    },
    {
      name: "registrationClose",
      prettyName: "Last day to register for session",
      type: "date",
      ariaLabel: "Last Day To Register",
      required: true,
    },
    {
      name: "formsDue",
      prettyName: "Participant forms submission due date",
      type: "date",
      ariaLabel: "Participant forms due date",
      required: true,
    },
    {
      name: "formsClose",
      prettyName: "Last date for participant forms to be updated",
      type: "date",
      ariaLabel: "Last date for participant forms to be updated",
      required: true,
    },
  ];

  const feeFields: Field[] = [
    {
      name: "fee",
      prettyName: "Fee",
      type: "number",
      ariaLabel: "fee",
      required: true,
      inputProps: {
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      },
    },
    {
      name: "deposit",
      prettyName: "Deposit",
      type: "number",
      ariaLabel: "Deposit",
      required: true,
      inputProps: {
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      },
    },
    {
      name: "campusAdminFee",
      prettyName: "Campus Administrative Fee",
      type: "number",
      ariaLabel: "Campus Administrative Fee",
      required: true,
      inputProps: {
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      },
    },
  ];

  const financialInfoFields: Field[] = [
    {
      name: "glCode",
      prettyName: "General Ledger Code",
      ariaLabel: "General Ledger Code",
      type: "text",
      required: false,
      inputProps: {
        maxLength: 30,
      },
      helperText:
        "30 character maximum. This is an optional field which can be used for financial reporting and will be sent to CampDoc. This code will not appear to participants when they register in CampDoc.",
    },
  ];

  const supplmentalFields: Field[] = [
    {
      name: "grantFunded",
      prettyName: "Will this session be grant funded?",
      ariaLabel: "Grant funded?",
      type: "select",
      required: true,
      items: yesNoItems,
    },
    {
      name: "upwardBoundTrio",
      prettyName:
        "Will this session be funded under Upward Bound/Trio funding?",
      ariaLabel: "Upward Bound/TRIO Scholarship?",
      type: "select",
      required: true,
      items: yesNoItems,
    },
    {
      name: "dpiScholarship",
      prettyName:
        "Will this session be funded under DPI Precollege Scholarship funding?",
      ariaLabel: "DPI Scholarship?",
      type: "select",
      required: true,
      items: yesNoItems,
    },
    {
      name: "athleticActivity",
      prettyName: "Does this session include athletic activities?",
      ariaLabel: "Athletic activities?",
      type: "select",
      required: true,
      items: yesNoItems,
    },
    {
      name: "musicActivity",
      prettyName: "Will this session include any music activities?",
      ariaLabel: "Music activities?",
      type: "select",
      required: true,
      items: yesNoItems,
    },
    {
      name: "waterActivity",
      prettyName: "Will this session have any water activities?",
      ariaLabel: "Water activities?",
      type: "select",
      required: true,
      items: yesNoItems,
    },
    {
      name: "inStateTravel",
      prettyName:
        "Will the activities in this session include in-state travel?",
      ariaLabel: "Grant Funded?",
      type: "select",
      required: true,
      items: yesNoItems,
    },
    {
      name: "outStateTravel",
      prettyName:
        "Will the activities in this session travel outside the state or country?",
      ariaLabel: "Grant Funded?",
      type: "select",
      required: true,
      items: yesNoItems,
    },
    {
      name: "forCredit",
      prettyName: "Is this session for credit?",
      ariaLabel: "For credit?",
      type: "select",
      required: true,
      items: yesNoItems,
    },
    {
      name: "league",
      prettyName: "Is this a league activity?",
      ariaLabel: "League activity?",
      type: "select",
      required: true,
      items: yesNoItems,
    },
  ];

  const HalfWrapper = (props: WrapperProps) => (
    <Grid item xs={12} md={6}>
      <Typography variant="h6" component="h3" style={{ marginBottom: 10 }}>
        {props.prettyName}
      </Typography>
      {props.children}
    </Grid>
  );

  const FullWrapper = (props: WrapperProps) => (
    <Grid item xs={12}>
      <Typography variant="h6" component="h3" style={{ marginBottom: 10 }}>
        {props.prettyName}
      </Typography>
      {props.children}
    </Grid>
  );

  const groups: Group[] = [
    {
      name: "session-information-form-group",
      prettyName: "Session Information",
      fields: fields,
      wrapper: FullWrapper,
    },
    {
      name: "session-type-form-group",
      prettyName: "Location & Type Information",
      fields: typeFields,
      wrapper: HalfWrapper,
    },
    {
      name: "session-fees-form-group",
      prettyName: "Fees",
      fields: feeFields,
      wrapper: HalfWrapper,
    },
    {
      name: "session-date-form-group",
      prettyName: "Dates",
      fields: dateFields,
      wrapper: HalfWrapper,
    },
    {
      name: "supplemental-fields-form-group",
      prettyName: "Supplemental Information",
      fields: supplmentalFields,
      wrapper: HalfWrapper,
    },
    {
      name: "session-financial-info-group",
      prettyName: "Financial Information",
      fields: financialInfoFields,
      wrapper: HalfWrapper,
    },
  ];

  const formSchema: FormSchema<Partial<Session>> = {
    handleSubmit: handleSubmit,
    validationSchema: validationSchema,
    initialValues: session,
    groups: groups,
  };

  return (
    <Card aria-label="new session form" className="uw-card">
      <YarsCardHeader
        component="h2"
        title={
          session.name
            ? `Edit ${session.name || ""} Session`
            : "Create New Session"
        }
      />
      <CardContent>
        <FormGenerator schema={formSchema} />
      </CardContent>
    </Card>
  );
}

export default SessionForm;
