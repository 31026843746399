import { Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { object, string } from "yup";
import sessionClient from "../api/sessionClient";
import FormGenerator, { Field, Group } from "../components/forms/FormGenerator";
import {
  CloseDialogButton,
  YarsDialog,
  YarsDialogContent,
  YarsDialogTitle,
} from "../components/YarsDialog";
import { useNotificationContext } from "../context/NotificationContext";
import { Session } from "./types";

const CancelSessionDialog = ({
  session,
  open,
  handleClose,
  setSession,
}: {
  session: Session;
  open: boolean;
  handleClose: () => void;
  setSession: Dispatch<SetStateAction<Session>>;
}) => {
  const { setNotification } = useNotificationContext();

  const handleSubmit = (session: Partial<Session>) => {
    const { canceled, canceledOn, ...rest } = session;

    handleClose();
    sessionClient
      .update({ canceled: true, canceledOn: new Date(), ...rest })
      .then((session) => {
        const { runStatus, canceled } = session;
        setSession((initial) => ({ ...initial, runStatus, canceled }));
        setNotification({
          type: "success",
          message: `${session.name} was canceled`,
        });
      })
      .catch((_) => {
        setNotification({
          type: "error",
          message:
            "An error occurred canceling this session. Please ensure all required fields are filled out before canceling.",
        });
      });
  };

  const validationSchema = object().shape({
    canceled: string()
      .matches(/cancel/)
      .required(),
  });
  const fields: Field[] = [
    {
      name: "canceled",
      prettyName: "Cancel?",
      type: "text",
      required: true,
      ariaLabel: "Cancel?",
    },
  ];
  const groups: Group[] = [
    {
      name: "",
      prettyName: "Cancel Session",
      fields: fields,
    },
  ];

  const schema = {
    handleSubmit: handleSubmit,
    validationSchema: validationSchema,
    initialValues: session,
    groups: groups,
    saveText: "Submit",
    handleClose: handleClose,
  };

  return (
    <YarsDialog
      open={open}
      aria-labelledby="session-cancel-dialog-title"
      aria-describedby="session-cancel-dialog-content"
    >
      <YarsDialogTitle id="session-cancel-dialog-title">
        Cancel Session <CloseDialogButton onClose={handleClose} />
      </YarsDialogTitle>
      <YarsDialogContent>
        <Typography sx={{ marginBottom: "1.5rem" }}>
          After canceling a session, program admins must ensure that the
          corresponding session is canceled in any vendor systems used by this
          program, like CampDoc or Volunteer Matters.
        </Typography>
        <Typography sx={{ marginBottom: "1.5rem" }}>
          <strong>
            Please type &ldquo;cancel&rdquo; to cancel this session. This
            operation cannot be undone.
          </strong>
        </Typography>
        <FormGenerator schema={schema} />
      </YarsDialogContent>
    </YarsDialog>
  );
};

export default CancelSessionDialog;
