import { Card, CardContent } from "@mui/material";
import { useState } from "react";
import { Redirect } from "react-router";
import { useParams } from "react-router-dom";
import { number, object, string } from "yup";
import { ApiError } from "../../api/errors";
import sessionClient from "../../api/sessionClient";
import { DEFAULT_ERROR_NOTIFICATION } from "../../common/constants";
import { Redirect as RedirectType } from "../../common/types";
import FormGenerator, {
  Field,
  FormSchema,
  Group,
} from "../../components/forms/FormGenerator";
import YarsCardHeader from "../../components/YarsCardHeader";
import { useNotificationContext } from "../../context/NotificationContext";
import { ageRangeItems, Session } from "../types";

type ParticipantFormProps = {
  session: Session;
};

function ParticipantForm(props: ParticipantFormProps) {
  const [session] = useState<Session>(props.session || ({} as Session));
  const { setNotification } = useNotificationContext();
  const [redirectObj, setRedirectObj] = useState<RedirectType>({
    redirect: false,
    uri: "",
  });

  const validationSchema = object().shape({
    maxParticipants: number()
      .nullable()
      .optional()
      .typeError("Maximum Number of Participants must be a number."),
    registeredParticipants: number()
      .nullable()
      .optional()
      .typeError("Number of Registered Participants must be a number."),
    ageRange: string(),
  });

  const { programSlug } = useParams<{
    programSlug: string;
  }>();

  if (redirectObj.redirect) {
    return <Redirect push to={redirectObj.uri} />;
  }

  function handleSubmit(
    updatedFields: Partial<Session>,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    if (session.id) {
      sessionClient
        .update({ ...session, ...updatedFields })
        .then((sessionResponse) => {
          setNotification({
            message: "Participants Updated!",
            type: "success",
          });
          setSubmitting(false);
          setRedirectObj({
            redirect: true,
            uri:
              "/programs/" +
              programSlug +
              "/sessions/" +
              sessionResponse.slug +
              "/",
          });
        })
        .catch((e: Error) => {
          if (e instanceof ApiError && e.fieldErrors) {
            const errors = e.fieldErrors;
            // eslint-disable-next-line
            for (const [_, errorMessage] of Object.entries(errors)) {
              setNotification({
                message: errorMessage,
                type: "error",
              });
            }
          } else {
            console.error(
              `Unable to update session ${session.id ?? ""}: ${e.message}`
            );
            setNotification({
              message: DEFAULT_ERROR_NOTIFICATION,
              type: "error",
            });
            setSubmitting(false);
          }
        });
    } else {
      setNotification({
        message: DEFAULT_ERROR_NOTIFICATION,
        type: "error",
      });
      setSubmitting(false);
    }
  }

  const fields: Field[] = [
    {
      name: "maxParticipants",
      prettyName: "Maximum Number of Participants",
      type: "number",
      required: false,
      ariaLabel: "maximum number of participants",
    },
    {
      name: "registeredParticipants",
      prettyName: "Number of Registered Participants",
      type: "number",
      required: false,
      ariaLabel: "number of registered participants",
    },
    {
      name: "ageRange",
      prettyName: "Age Range",
      type: "select",
      required: false,
      ariaLabel: "age range",
      items: ageRangeItems,
    },
  ];

  const groups: Group[] = [
    {
      name: "participant-form-group",
      prettyName: "Session Participants",
      fields: fields,
    },
  ];

  const formSchema: FormSchema<Session> = {
    handleSubmit: handleSubmit,
    validationSchema: validationSchema,
    initialValues: session,
    groups: groups,
  };

  return (
    <Card variant="outlined" aria-label="participant form" className="uw-card">
      <YarsCardHeader component="h2" title="Session Participants" />
      <CardContent>
        <FormGenerator schema={formSchema} />
      </CardContent>
    </Card>
  );
}

export default ParticipantForm;
