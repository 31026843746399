import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditNoteIcon from "@mui/icons-material/Edit";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import YarsCardHeader from "../components/YarsCardHeader";
import { useSessionContext } from "../context/SessionContext";
import { Program, Status } from "./types";

/**
 * Return a nicely formatted Status as a string.
 */
export const statusLabel = (status: Status) => {
  return status.slice(0, 1).toUpperCase() + status.slice(1);
};

/**
 * A ProgramStatusItem is a single, complete ListItem intended for displaying
 * a program's status. It includes the status as a label, an appropriate icon,
 * and help text.
 */
const ProgramStatusItem = ({ status }: { status: Status }) => {
  if (!status) return <></>;

  const statusTitle = statusLabel(status);
  const ariaId = "status" + statusTitle;

  return (
    <ListItem aria-labelledby={ariaId}>
      <ListItemAvatar className={getProgramStatusClassName(status)}>
        <Avatar>{getProgramStatusAvatar(status)}</Avatar>
      </ListItemAvatar>
      <ListItemText
        id={ariaId}
        secondary={
          <>
            {statusTitle}
            <br />
            <Typography component="span">
              {getProgramStatusDescription(status)}
            </Typography>
          </>
        }
      ></ListItemText>
    </ListItem>
  );
};

const ApproveButton = ({
  program,
  onClick,
  children,
}: {
  program: Program;
  onClick: () => void;
  children: string;
}) => {
  const isDisabled = program.sessions && program.sessions.length < 1;

  return (
    <Button
      aria-label={`Review ${program.slug} program`}
      className={isDisabled ? "" : "primary-action-btn"}
      onClick={onClick}
      variant="outlined"
      size="medium"
      disabled={isDisabled}
    >
      {children}
    </Button>
  );
};

function ProgramStatus(props: {
  program: Program;
  onSubmit: () => void;
  onReview: () => void;
  onDeny: () => void;
  isUpdating: boolean;
}) {
  const { program, onSubmit, onReview, onDeny, isUpdating } = props;
  const { user } = useSessionContext();

  // If seen by liaison, don't show Submit For Review button
  function cardAction(status: Status) {
    if (status === "draft") {
      return (
        <Button
          aria-label={`Submit Program ${program.slug} for review`}
          variant="outlined"
          onClick={onSubmit}
          className="primary-action-btn"
        >
          Submit for review
        </Button>
      );
    } else if (status === "denied") {
      return (
        <Button
          aria-label={`Resubmit Program ${program.slug} for review`}
          variant="outlined"
          onClick={onSubmit}
          className="primary-action-btn"
        >
          Resubmit
        </Button>
      );
    }

    return <></>;
  }

  return (
    <Card
      variant="outlined"
      aria-label="Program Status Information"
      className="uw-card-details"
    >
      <YarsCardHeader title="Status" action={cardAction(program.status)} />
      <CardContent>
        {isUpdating ? (
          <CircularProgress />
        ) : (
          <>
            <List dense={true} className="details-list">
              <ProgramStatusItem status={program.status} />
            </List>
            <LiaisonComments status={program.status}>
              {program.liaisonComments}
            </LiaisonComments>
          </>
        )}
      </CardContent>
      {/* If seen by liaison and program was submitted, show buttons to Review or Deny */}
      {user!.isOrganizationLiaison && program.status === "submitted" && (
        <CardActions>
          <Box m={2}>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <ApproveButton program={program} onClick={onReview}>
                  Review
                </ApproveButton>
              </Grid>
              <Grid item>
                <Button
                  aria-label={`Deny ${program.slug} program`}
                  className="secondary-action-btn"
                  onClick={onDeny}
                  variant="outlined"
                  size="medium"
                  startIcon={<CloseIcon />}
                >
                  Deny
                </Button>
              </Grid>
            </Grid>
          </Box>
        </CardActions>
      )}
    </Card>
  );
}

const LiaisonComments = ({
  status,
  children,
}: {
  status: Status;
  children: string | undefined;
}) => {
  if (status !== "denied" || !children) return null;
  return (
    <Alert severity="error" sx={{ marginTop: "1rem" }}>
      {children}
    </Alert>
  );
};

/**
 * Get the appropriate description given a Program's status.
 * @param string status
 * @returns string
 */
const getProgramStatusDescription = (status: Status) => {
  if (status === "submitted") {
    return "Program has not been reviewed but is in the queue for review.";
  } else if (status === "reviewed") {
    return "Program has been reviewed. Programs using CampDoc for participant registration will be automatically submitted.";
  } else if (status === "denied") {
    return "Program has been reviewed and denied.  Please review, enter missing information, and resubmit.";
  }

  return "Program has been created in YARS but has not been submitted for review.";
};

/**
 * Get the appropriate avatar CSS class name given a Program's status.
 * @param Status status
 * @returns string
 */
const getProgramStatusClassName = (status: Status) => {
  if (status === "submitted") {
    return "info";
  } else if (status === "reviewed") {
    return "success";
  } else if (status === "denied") {
    return "warning";
  }

  return "";
};

/**
 * Get the appropriate avatar given a Program's status.
 * @param Status status
 * @returns JSX.Element
 */
const getProgramStatusAvatar = (status: Status) => {
  if (status === "submitted") {
    return <ViewTimelineIcon />;
  } else if (status === "reviewed") {
    return <CheckIcon />;
  } else if (status === "denied") {
    return <CloseIcon />;
  }

  return <EditNoteIcon />;
};

export default ProgramStatus;
