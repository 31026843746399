import { Button, Card, CardContent, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { object, string } from "yup";
import programClient from "../api/programClient";
import sessionClient from "../api/sessionClient";
import { INotes } from "../common/types";
import FormGenerator, { Field, Group } from "../components/forms/FormGenerator";
import YarsCardHeader from "../components/YarsCardHeader";
import {
  CloseDialogButton,
  YarsDialog,
  YarsDialogContent,
  YarsDialogTitle,
} from "../components/YarsDialog";
import { useNotificationContext } from "../context/NotificationContext";
import { isProgram } from "../programs/types";

const NotesCard = <T extends INotes>({
  item,
  setItem,
}: {
  item: T;
  setItem: Dispatch<SetStateAction<T>>;
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { setNotification } = useNotificationContext();

  const validationSchema = object().shape({
    notes: string().trim().nullable(),
  });

  const fields: Field[] = [
    {
      name: "notes",
      prettyName: "Notes",
      type: "text",
      required: true,
      ariaLabel: "notes",
      fieldProps: {
        multiline: true,
        minRows: 5,
        maxRows: 5,
      },
    },
  ];

  const groups: Group[] = [
    {
      name: "notes-form-group",
      prettyName: "Notes Form",
      fields: fields,
    },
  ];

  const submit = (item: T) => {
    let client;
    // If the item has a `program` field, we're looking at a Session
    // and want the Session client
    if (isProgram(item)) {
      client = programClient;
    } else {
      client = sessionClient;
    }

    client
      .update(item)
      .then((newItem) => {
        const { notes, updatedAt } = newItem;
        setNotification({
          type: "success",
          message: "Notes were updated!",
        });
        setItem((initial) => ({ ...initial, notes, updatedAt }));
        setDialogOpen(false);
      })
      .catch((error) => console.error(error));
  };

  const schema = {
    handleSubmit: submit,
    validationSchema: validationSchema,
    initialValues: item,
    groups: groups,
    saveText: "Submit",
    handleClose: () => setDialogOpen(false),
  };

  return (
    <>
      <YarsDialog
        open={dialogOpen}
        aria-labelledby="notes-dialog-title"
        aria-describedby="notes-dialog-content"
      >
        <YarsDialogTitle id="notes-dialog-title">
          Edit Notes <CloseDialogButton onClose={() => setDialogOpen(false)} />
        </YarsDialogTitle>
        <YarsDialogContent>
          <Typography sx={{ mb: "1.5rem" }}>
            Notes are visible to all Program Admins and Liaisons. They are not
            shared with third party vendors and are for interal use only.
          </Typography>
          <FormGenerator schema={schema} />
        </YarsDialogContent>
      </YarsDialog>
      <Card
        variant="outlined"
        aria-label="Notes"
        className="uw-card-details"
        sx={{ mb: 2 }}
      >
        <YarsCardHeader
          title="Notes"
          action={
            <Button onClick={() => setDialogOpen(true)}>Edit Notes</Button>
          }
        />
        <CardContent>
          <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
            {item.notes || "None."}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default NotesCard;
