import { Card, CardContent } from "@mui/material";
import { useState } from "react";
import { Redirect, useParams } from "react-router";
import { number, object, string } from "yup";
import { ApiError } from "../../api/errors";
import sessionClient from "../../api/sessionClient";
import { DEFAULT_ERROR_NOTIFICATION } from "../../common/constants";
import { Redirect as RedirectType } from "../../common/types";
import FormGenerator, {
  Field,
  FormSchema,
  Group,
} from "../../components/forms/FormGenerator";
import YarsCardHeader from "../../components/YarsCardHeader";
import { useNotificationContext } from "../../context/NotificationContext";
import { Session, yesNoItems } from "../types";

type StaffFormProps = {
  session: Session;
};

function StaffForm(props: StaffFormProps) {
  const [session] = useState<Session>(props.session || ({} as Session));
  const { setNotification } = useNotificationContext();
  const [redirectObj, setRedirectObj] = useState<RedirectType>({
    redirect: false,
    uri: "",
  });

  const validationSchema = object().shape({
    staffCount: number()
      .typeError("Staff Count must be a number")
      .integer("Staff Count must be a positive integer")
      .positive("Staff Count must be a positive integer"),
    staffYoungestAge: number()
      .typeError("Youngest Staff Age must be a number")
      .integer("Youngest Staff Age must be a positive integer")
      .positive("Youngest Staff Age must be a positive integer"),
    hasVolunteers: string().required("This field is required"),
    volunteerSupervisor: string().when("hasVolunteers", {
      is: "y",
      then: string().required(
        "Volunteer Supervisor is required when a session has volunteers"
      ),
      otherwise: string().nullable(),
    }),
    volunteerSupervisorEmail: string().when("hasVolunteers", {
      is: "y",
      then: string().required(
        "Volunteer Supervisor Email is required when a session has volunteers"
      ),
      otherwise: string().nullable(),
    }),
  });

  const { programSlug } = useParams<{ programSlug: string }>();

  if (redirectObj.redirect) {
    return <Redirect push to={redirectObj.uri} />;
  }

  function handleSubmit(
    session: Partial<Session>,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    if (session.id) {
      sessionClient
        .update(session)
        .then((sessionResponse) => {
          setNotification({
            message: "Staff Updated!",
            type: "success",
          });
          setSubmitting(false);
          setRedirectObj({
            redirect: true,
            uri:
              "/programs/" +
              programSlug +
              "/sessions/" +
              sessionResponse.slug +
              "/",
          });
        })
        .catch((e: Error) => {
          if (e instanceof ApiError && e.fieldErrors) {
            const errors = e.fieldErrors;
            for (const [_, errorMessage] of Object.entries(errors)) {
              setNotification({
                message: errorMessage,
                type: "error",
              });
            }
          } else {
            console.error(
              `Unable to update session ${session.id ?? ""}: ${e.message}`
            );
            setNotification({
              message: DEFAULT_ERROR_NOTIFICATION,
              type: "error",
            });
            setSubmitting(false);
          }
        });
    } else {
      setNotification({
        message: DEFAULT_ERROR_NOTIFICATION,
        type: "error",
      });
      setSubmitting(false);
    }
  }

  const fields: Field[] = [
    {
      name: "staffCount",
      prettyName: "Staff Count",
      type: "number",
      required: true,
      ariaLabel: "staff count",
    },
    {
      name: "staffYoungestAge",
      prettyName: "Youngest Staff Member Age",
      type: "number",
      required: true,
      ariaLabel: "staff youngest age",
    },
    {
      name: "hasVolunteers",
      prettyName: "Does this session have volunteers?",
      type: "select",
      required: true,
      ariaLabel: "Does this session have volunteers?",
      items: yesNoItems,
    },
    {
      name: "volunteerSupervisor",
      prettyName: "Volunteer Supervisor",
      type: "text",
      required: false,
      ariaLabel: "Volunteer supervisor",
      conditional: { field: "hasVolunteers", is: "y" },
    },
    {
      name: "volunteerSupervisorEmail",
      prettyName: "Volunteer Supervisor Email",
      type: "text",
      required: false,
      ariaLabel: "Volunteer supervisor email",
      conditional: { field: "hasVolunteers", is: "y" },
    },
    {
      name: "staffBackgroundChecks",
      prettyName: "Have all staff completed background checks?",
      type: "yesNo",
      required: false,
      ariaLabel: "staff background checks",
    },
    {
      name: "staffRequiredTraining",
      prettyName: "Have all staff completed the required training?",
      type: "yesNo",
      required: false,
      ariaLabel: "staff requried training",
    },
    {
      name: "staffOrientation",
      prettyName: "Does your event have staff orientation?",
      type: "yesNo",
      required: false,
      ariaLabel: "staff background checks",
    },
  ];

  const groups: Group[] = [
    {
      name: "staff-form-group",
      prettyName: "Staff Form",
      fields: fields,
    },
  ];

  const formSchema: FormSchema<Session> = {
    handleSubmit: handleSubmit,
    validationSchema: validationSchema,
    initialValues: session,
    groups: groups,
  };

  return (
    <Card aria-label="new staff member form" className="uw-card">
      <YarsCardHeader component="h2" title="Session Staff" />
      <CardContent>
        <FormGenerator schema={formSchema} />
      </CardContent>
    </Card>
  );
}

export default StaffForm;
