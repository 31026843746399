import { Button, ButtonProps } from "@mui/material";
import { SxProps } from "@mui/system";
import React from "react";

/**
 * A basic Button for use as a primary card action.
 * This component takes all MUI Button props.
 */
export const YarsCardActionButton = React.forwardRef(
  <C extends React.ElementType>(
    props: ButtonProps<C, { component?: C } & SxProps>,
    ref?: React.Ref<HTMLButtonElement>
  ) => {
    const { children, ...rest } = props;
    return (
      <Button
        ref={ref}
        className="primary-action-btn"
        size="medium"
        variant="contained"
        {...rest}
      >
        {children}
      </Button>
    );
  }
);
YarsCardActionButton.displayName = "YarsCardActionButton";

export interface YarsFileUploadButtonProps {
  children: string;
  name: string;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  accept?: string;
}

/**
 * A Button component with a hidden file input.
 */
export const YarsFileUploadButton = React.forwardRef<
  HTMLInputElement,
  YarsFileUploadButtonProps
>(({ children, name, onChange, accept }, ref?: React.Ref<HTMLInputElement>) => {
  return (
    <Button component="label">
      <input
        type="file"
        name={name}
        hidden
        ref={ref}
        onChange={onChange}
        accept={accept}
      />
      {children}
    </Button>
  );
});
YarsFileUploadButton.displayName = "YarsFileUploadButton";

export const YarsDownloadButton = ({
  children,
  href,
}: {
  children: string;
  href: string;
}) => {
  return <Button href={href}>{children}</Button>;
};
