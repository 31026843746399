import { OrganizationJson } from "../auth/types";
import { api } from "./api";

export type CampusProgramsCountJson = OrganizationJson & {
  active_programs: number;
};

export type CampusProgramsCountReport = {
  id: number;
  name: string;
  eppnDomain: string;
  activePrograms: number;
};

const getCampusProgramsCount = async () => {
  const response = await api.call("/reports/programs");
  const json = (await response.json()) as CampusProgramsCountJson[];
  return json.map((org) => ({
    id: org.id,
    name: org.name,
    eppnDomain: org.eppn_domain,
    activePrograms: org.active_programs,
  }));
};

const reportClient = {
  getCampusProgramsCount: getCampusProgramsCount,
};

export default reportClient;
