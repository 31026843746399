import { Card, CardContent, Chip, Tooltip } from "@mui/material";

/**
 * A card wrapper for displaying active filter chips.
 * @param children React.ReactNode
 * @returns JSX.Element
 */
export const ActiveFiltersCard = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Card sx={{ mb: 2, pt: 1 }}>
      <CardContent>{children}</CardContent>
    </Card>
  );
};

/**
 * A generic chip for displaying an active list filter.
 * @param children {string}
 * @param tooltipLabel {string}
 * @param onDelete () => {} | undefined
 * @returns JSX.Element
 */
export const ActiveFilterChip = ({
  children,
  tooltipLabel,
  onDelete,
}: {
  children: string;
  tooltipLabel: string;
  onDelete?: () => void;
}) => {
  return (
    <Tooltip arrow title={tooltipLabel}>
      <Chip
        className="uw-chip"
        color="primary"
        label={children}
        sx={{ mr: 1 }}
        onDelete={onDelete}
      />
    </Tooltip>
  );
};

/**
 * A filter chip for indicating this list is filtered to only show items
 * that the user has permission to see.
 * @returns JSX.Element
 */
export const VisibleToMeChip = () => {
  return (
    <ActiveFilterChip
      tooltipLabel="These are only the sessions you have access to view.
                This table may not reflect all sessions in your organization."
    >
      Visible to me
    </ActiveFilterChip>
  );
};
