import { Card, CardContent } from "@mui/material";
import { useState } from "react";
import { Redirect } from "react-router";
import { object, string } from "yup";
import { ApiError } from "../api/errors";
import programClient from "../api/programClient";
import { DEFAULT_ERROR_NOTIFICATION } from "../common/constants";
import { Notification, Redirect as RedirectType } from "../common/types";
import FormGenerator, {
  Field,
  FormSchema,
  Group,
} from "../components/forms/FormGenerator";
import YarsCardHeader from "../components/YarsCardHeader";
import { useNotificationContext } from "../context/NotificationContext";
import { Program } from "./types";

type ProgramFormProps = {
  program?: Program;
};

/**
 * A form for a program. If the props program does not contain an id
 * then create a new program. Otherwise update the program with that ID
 */
function ProgramForm(props: ProgramFormProps) {
  const [program] = useState<Program>(props.program || ({} as Program));
  const [redirectObj, setRedirectObj] = useState<RedirectType>({
    redirect: false,
    uri: "",
  });
  const { setNotification } = useNotificationContext();

  /**
   * On submit either create a new program or update the existing one.
   * If creating a program redirect when that program is created.
   */
  function handleSubmit(
    program: Partial<Program>,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    if (program.id) {
      programClient
        .update(program)
        .then((programResponse) => {
          setNotification({
            message: "Program Updated!",
            type: "success",
          });
          setSubmitting(false);
          setRedirectObj({
            redirect: true,
            uri: `/programs/${programResponse.slug}/`,
          });
        })
        .catch((e: Error) => {
          console.error("Unable to update program: " + e.message);
          setNotification({
            message: "A problem occurred with your request",
            type: "error",
          });
          setSubmitting(false);
        });
    } else {
      programClient
        .create(program)
        .then((json) => {
          setNotification({
            message: `${json.name} was created`,
            type: "success",
          } as Notification);
          setSubmitting(false);
          setRedirectObj({
            redirect: true,
            uri: "/programs/" + json.slug + "/",
          });
        })
        .catch((e: Error) => {
          if (e instanceof ApiError && e.fieldErrors) {
            const errors = e.fieldErrors;
            // eslint-disable-next-line
            for (const [_, errorMessage] of Object.entries(errors)) {
              setNotification({
                message: errorMessage,
                type: "error",
              });
            }
          } else {
            console.error("Unable to create program: " + e.message);
            setNotification({
              message: DEFAULT_ERROR_NOTIFICATION,
              type: "error",
            });
            setSubmitting(false);
          }
        });
    }
  }

  /**
   * If the redirect object's redirect key is true redirect to the URI.
   */
  if (redirectObj.redirect) {
    return <Redirect push to={redirectObj.uri} />;
  }

  /**
   * Form Validation Schema
   */
  const validationSchema = object().shape({
    name: string().trim().required("Program Name is Required"),
  });

  const fields: Field[] = [
    {
      name: "name",
      prettyName: "Name",
      type: "text",
      required: true,
      ariaLabel: "program name",
    },
    {
      name: "description",
      prettyName: "Description",
      type: "text",
      required: true,
      ariaLabel: "program description",
      fieldProps: {
        multiline: true,
      },
    },
    {
      name: "sponsorUnit",
      prettyName: "Sponsoring Unit",
      type: "sponsorUnitSelect",
      required: false,
      ariaLabel: "program sponsoring unit",
    },
    {
      name: "director",
      prettyName: "Program Director",
      type: "personSelect",
      required: false,
      ariaLabel: "program director",
    },
    {
      name: "website",
      prettyName: "Website",
      type: "text",
      required: false,
      ariaLabel: "program website",
    },
    {
      name: "participantRegistrationSystem",
      prettyName: "What participant registration system is used?",
      type: "select",
      required: false,
      ariaLabel: "Participant registration system",
      items: [
        { val: "CampDoc", text: "CampDoc" },
        { val: "Other", text: "Other" },
        { val: "Unknown", text: "Unknown" },
      ],
    },
  ];

  const groups: Group[] = [
    { name: "program-form-group", prettyName: "Program Form", fields: fields },
  ];

  const formSchema: FormSchema<Program> = {
    handleSubmit: handleSubmit,
    validationSchema: validationSchema,
    initialValues: program,
    groups: groups,
  };

  return (
    <Card aria-label="new program form" className="uw-card">
      <YarsCardHeader
        component="h2"
        title={
          program.name
            ? `Edit ${program.name || ""} Program`
            : "Create New Program"
        }
      />
      <CardContent>
        <FormGenerator schema={formSchema} />
      </CardContent>
    </Card>
  );
}

export default ProgramForm;
