import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ApiError } from "../api/errors";
import sessionClient from "../api/sessionClient";
import ErrorMessage from "../components/ErrorMessage";
import {
  ActiveFilterChip,
  ActiveFiltersCard,
  VisibleToMeChip,
} from "../components/filter";
import ListPage from "../components/ListPage";
import { WithTitle } from "../context/TitleContext";
import AllSessionsTable from "./AllSessionsTable";
import { Session } from "./types";

/**
 * View All sessions a user has access to in their organization.
 */
export default function SessionAll() {
  const createdAtDate =
    new URLSearchParams(useLocation().search).get("createdAt") ?? null;
  const [sessions, setSessions] = useState<Array<Session>>([]);
  const [apiError, setApiError] = useState<ApiError | null>(null);
  const [createdAt, setCreatedAt] = useState<string | null>(createdAtDate);

  useEffect(() => {
    if (createdAt) {
      sessionClient
        .getByCreatedAt(createdAt)
        .then((sessions: Array<Session>) => setSessions(sessions))
        .catch((e: ApiError) => setApiError(e));
    } else {
      sessionClient
        .getAll()
        .then((sessions: Array<Session>) => setSessions(sessions))
        .catch((e: ApiError) => setApiError(e));
    }
  }, [createdAt]);

  function handleChipDelete() {
    setCreatedAt(null);
  }

  function getCreatedAtChip() {
    if (createdAt) {
      const createdOnDate = DateTime.fromISO(createdAt).toLocaleString(
        DateTime.DATE_FULL
      );
      const tooltipTitle = `Showing only sessions first added to YARS on ${createdOnDate}.`;

      return (
        <ActiveFilterChip
          tooltipLabel={tooltipTitle}
          onDelete={handleChipDelete}
        >{`Created On: ${createdOnDate}`}</ActiveFilterChip>
      );
    }
  }

  return (
    <WithTitle title="Sessions">
      {apiError ? (
        <ErrorMessage message="No Sessions Found" />
      ) : (
        <>
          <ActiveFiltersCard>
            <VisibleToMeChip />
            {createdAt && getCreatedAtChip()}
          </ActiveFiltersCard>
          <ListPage
            table={
              <AllSessionsTable
                sessions={sessions}
                createdAt={createdAtDate ? new Date(createdAtDate) : undefined}
              />
            }
          />
        </>
      )}
    </WithTitle>
  );
}
