import AssignmentIcon from "@mui/icons-material/Assignment";
import MoneyIcon from "@mui/icons-material/AttachMoney";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import ClassIcon from "@mui/icons-material/Class";
import DeleteIcon from "@mui/icons-material/Delete";
import EventIcon from "@mui/icons-material/Event";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import PersonIcon from "@mui/icons-material/Person";
import PriorityIcon from "@mui/icons-material/PriorityHigh";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import DeadlineIcon from "@mui/icons-material/Schedule";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import {
  Dispatch,
  FormEvent,
  ReactElement,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Redirect } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import documentClient, {
  Document,
  DocumentType,
  typeMap,
} from "../api/documentClient";
import { ApiError } from "../api/errors";
import sessionClient from "../api/sessionClient";
import { Redirect as RedirectType } from "../common/types";
import {
  YarsCardActionButton,
  YarsDownloadButton,
  YarsFileUploadButton,
} from "../components/button";
import ErrorMessage from "../components/ErrorMessage";
import DeleteDialog from "../components/forms/DeleteDialog";
import { YarsAvatarListItem } from "../components/list";
import NotesCard from "../components/NotesCard";
import YarsCardHeader from "../components/YarsCardHeader";
import { useNotificationContext } from "../context/NotificationContext";
import { useSessionContext } from "../context/SessionContext";
import { WithTitle } from "../context/TitleContext";
import NavLinkForward from "../navigation/NavLinkForward";
import { isPerson } from "../people/types";
import { isProgram } from "../programs/types";
import CancelSessionDialog from "./CancelSessionDialog";
import { DuplicateSessionDialog } from "./DuplicateSessionDialog";
import ParticipantCard from "./participants/ParticipantCard";
import StaffCard from "./staff/StaffCard";
import { RunStatus, Session } from "./types";

/**
 * Return a "pretty" string representation of a file size, given bytes.
 * @param bytes {number} file size in bytes
 * @returns string
 */
const prettyFileSize = (bytes: number) => {
  return `${(bytes * 0.000001).toFixed(1)} MB`;
};

const MAX_FILE_SIZE_BYTES = 2000000;
const MAX_FILE_SIZE_PRETTY = prettyFileSize(MAX_FILE_SIZE_BYTES);

const RunStatusAvatar = ({ runStatus }: { runStatus: RunStatus }) => {
  if (runStatus == "Canceled") {
    return (
      <ListItemAvatar className="error">
        <Avatar>
          <CancelIcon />
        </Avatar>
      </ListItemAvatar>
    );
  } else if (runStatus == "Pending") {
    return (
      <ListItemAvatar className="warning">
        <Avatar>
          <EventIcon />
        </Avatar>
      </ListItemAvatar>
    );
  } else if (runStatus == "Running") {
    return (
      <ListItemAvatar className="info">
        <Avatar>
          <SettingsIcon />
        </Avatar>
      </ListItemAvatar>
    );
  } else if (runStatus == "Completed") {
    return (
      <ListItemAvatar className="success">
        <Avatar>
          <CheckIcon />
        </Avatar>
      </ListItemAvatar>
    );
  } else {
    return null;
  }
};

/**
 * Session Detail page
 */
const SessionDetail = () => {
  const [session, setSession] = useState<Session>({} as Session);
  const [apiError, setApiError] = useState<ApiError | null>(null);
  const { sessionSlug } = useParams<{ sessionSlug: string }>();
  const [submitting, setSubmitting] = useState(false);
  const youthSupervisionPlanRef = useRef<HTMLInputElement>(null);
  const [youthSupervisionPlan, setYouthSupervisionPlan] = useState<File | null>(
    null
  );
  const healthAndSafetyPlanRef = useRef<HTMLInputElement>(null);
  const [healthAndSafetyPlan, setHealthAndSafetyPlan] = useState<File | null>(
    null
  );
  const emergencyPlanRef = useRef<HTMLInputElement>(null);
  const [emergencyPlan, setEmergencyPlan] = useState<File | null>(null);
  const escalationMatrixRef = useRef<HTMLInputElement>(null);
  const [escalationMatrix, setEscalationMatrix] = useState<File | null>(null);
  const orientationAgendaRef = useRef<HTMLInputElement>(null);
  const [orientationAgenda, setOrientationAgenda] = useState<File | null>(null);
  const thirdPartyAgreementRef = useRef<HTMLInputElement>(null);
  const [thirdPartyAgreement, setThirdPartyAgreement] = useState<File | null>(
    null
  );
  const staffRosterRef = useRef<HTMLInputElement>(null);
  const [staffRoster, setStaffRoster] = useState<File | null>(null);
  const { setNotification } = useNotificationContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [redirectObj, setRedirectObj] = useState<RedirectType>({
    redirect: false,
    uri: "",
  });

  if (redirectObj.redirect) {
    return <Redirect push to={redirectObj.uri} />;
  }

  const inputRefs = [
    youthSupervisionPlanRef,
    healthAndSafetyPlanRef,
    emergencyPlanRef,
    escalationMatrixRef,
    orientationAgendaRef,
    thirdPartyAgreementRef,
    staffRosterRef,
  ];

  const fileSetters = [
    setYouthSupervisionPlan,
    setHealthAndSafetyPlan,
    setEmergencyPlan,
    setEscalationMatrix,
    setOrientationAgenda,
    setThirdPartyAgreement,
    setStaffRoster,
  ];

  /**
   * Return `true` if a file has been uploaded, otherwise `false`.
   */
  const hasUploads = () => {
    return (
      youthSupervisionPlan !== null ||
      healthAndSafetyPlan !== null ||
      emergencyPlan !== null ||
      escalationMatrix !== null ||
      orientationAgenda !== null ||
      thirdPartyAgreement !== null ||
      staffRoster !== null
    );
  };

  useEffect(() => {
    if (sessionSlug) {
      sessionClient
        .getBySlug(sessionSlug)
        .then((sessionResponse: Session) => setSession(sessionResponse))
        .catch((e: ApiError) => setApiError(e));
    } else {
      console.error("No session slug");
    }
  }, [sessionSlug]);

  /**
   * Handle a file upload event.
   * This checks whether the uploaded file exceeds the maximum file size and posts
   * and error notification if it is. Otherwise, it sets the appropriate state with
   * `setState`.
   */
  const handleFileChange = (
    e: FormEvent<HTMLInputElement>,
    setState: React.Dispatch<React.SetStateAction<File | null>>
  ) => {
    if (e.target) {
      const node = e.target as HTMLInputElement;
      if (node.files && node.files.length > 0) {
        const file = node.files[0];
        if (file.size > MAX_FILE_SIZE_BYTES) {
          setNotification({
            type: "error",
            message: `${file.name} is too large (${prettyFileSize(
              file.size
            )}). Please choose a file under ${MAX_FILE_SIZE_PRETTY}.`,
          });
        } else {
          setState(file);
        }
      }
    }
  };

  /**
   * Submit all uploaded Documents.
   */
  const submitDocuments = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSubmitting(true);

    for (const inputRef of inputRefs) {
      const node = inputRef.current;
      if (node) {
        if (node.files && node.files.length > 0) {
          const file = node?.files[0];
          documentClient
            .create(file, session.id, typeMap[node.name] as DocumentType)
            .then((response) => {
              setNotification({
                type: "success",
                message: `${response.name} was successfully uploaded!`,
              });
            })
            .catch((e) => {
              if (e instanceof ApiError && e.fieldErrors) {
                const errors = e.fieldErrors;
                for (const [_, errorMessage] of Object.entries(errors)) {
                  setNotification({
                    type: "error",
                    message: errorMessage,
                  });
                }
              }
            })
            .finally(() => {
              // Clear files
              for (const setter of fileSetters) {
                setter(null);
              }
              setSubmitting(false);

              // Populate component with updated Session
              sessionClient
                .getBySlug(session.slug)
                .then((updatedSession) => {
                  setSession(updatedSession);
                })
                .catch((e) =>
                  console.error(
                    `Could not retrieve updated Session: ${JSON.stringify(e)}`
                  )
                );
            });
        }
      }
    }
  };

  const allowedFiledTypes =
    "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,\
     application/pdf,application/msword,application/vnd.oasis.opendocument.spreadsheet,\
     application/vnd.openxmlformats-officedocument.wordprocessingml.document,\
     application/vnd.oasis.opendocument.spreadsheet,application/vnd.oasis.opendocument.text,\
     text/plain,text/csv";

  const documents = [
    {
      file: youthSupervisionPlan,
      title: "Youth Supervision Plan",
      ref: youthSupervisionPlanRef,
      name: "youthSupervisionPlan",
      setDocument: setYouthSupervisionPlan,
    },
    {
      file: healthAndSafetyPlan,
      title: "Health & Safety Plan",
      ref: healthAndSafetyPlanRef,
      name: "healthAndSafetyPlan",
      setDocument: setHealthAndSafetyPlan,
    },
    {
      file: emergencyPlan,
      title: "Emergency Plan",
      ref: emergencyPlanRef,
      name: "emergencyPlan",
      setDocument: setEmergencyPlan,
    },
    {
      file: escalationMatrix,
      title: "Escalation Matrix",
      ref: escalationMatrixRef,
      name: "escalationMatrix",
      setDocument: setEscalationMatrix,
    },
    {
      file: orientationAgenda,
      title: "Orientation Agenda",
      ref: orientationAgendaRef,
      name: "orientationAgenda",
      setDocument: setOrientationAgenda,
    },
    {
      file: thirdPartyAgreement,
      title: "Third Party Agreement",
      ref: thirdPartyAgreementRef,
      name: "thirdPartyAgreement",
      setDocument: setThirdPartyAgreement,
    },
    {
      file: staffRoster,
      title: "Staff Roster",
      ref: staffRosterRef,
      name: "staffRoster",
      setDocument: setStaffRoster,
    },
  ];

  return (
    <>
      {apiError ? (
        <ErrorMessage message="No Session Found" />
      ) : (
        <WithTitle title={session.name}>
          <DuplicateSessionDialog
            session={session}
            open={dialogOpen}
            handleClose={() => setDialogOpen(false)}
          />
          <Grid container direction="row" justifyContent="flex-end">
            <Button
              aria-label="Duplicate session"
              className="createButton primary-action-btn"
              variant="contained"
              onClick={() => setDialogOpen(true)}
            >
              Duplicate
            </Button>
          </Grid>
          <Card
            sx={{ mb: 2 }}
            role="group"
            variant="outlined"
            aria-label="Session summary"
            className="uw-card-details"
          >
            <YarsCardHeader
              title="Summary"
              action={
                <Button
                  className="secondary-action-btn"
                  href={
                    session.program && isProgram(session.program)
                      ? `/programs/${session.program.slug}`
                      : undefined
                  }
                >
                  View Program
                </Button>
              }
            />
            <CardContent>
              <Typography variant="subtitle1" mx={1}>
                {session.description || "No description provided."}
              </Typography>
            </CardContent>
          </Card>
          <RunStatusCard session={session} setSession={setSession} />
          <Grid>
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
            >
              <Grid item xs={12} md={12} lg={6}>
                <SessionDetailsCard session={session} />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <SessionDatesCard session={session} />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
            >
              <Grid item xs={12} md={12} lg={6} mt={3}>
                <ParticipantCard session={session} />
              </Grid>
              <Grid item xs={12} md={12} lg={6} mt={3}>
                <StaffCard session={session} />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
            >
              <Grid item xs={12} md={12} lg={6} mt={3}>
                <Card
                  role="group"
                  variant="outlined"
                  aria-label="Manage forms"
                  className="uw-card-details"
                >
                  <YarsCardHeader title="Manage Forms" />
                  <CardContent>
                    <Typography sx={{ marginBottom: "0.5rem" }}>
                      Information about the following forms, along with
                      downloadable form templates, can be found at{" "}
                      <Link
                        href="https://www.wisconsin.edu/compliance/landing-page/child-safety/preparing-for-youth-activities/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Preparing for Youth Activities
                      </Link>
                      .
                    </Typography>
                    <List
                      dense={true}
                      className="details-list"
                      sx={{ marginTop: "1rem" }}
                    >
                      {documents.map((document) => (
                        <YarsAvatarListItem
                          avatar={<AssignmentIcon />}
                          key={document.name}
                        >
                          <ListItemText
                            secondary={
                              document.file?.name || "No file selected."
                            }
                          >
                            {document.title}
                          </ListItemText>
                          <YarsFileUploadButton
                            name={document.name}
                            ref={document.ref}
                            accept={allowedFiledTypes}
                            onChange={(e) => {
                              handleFileChange(e, document.setDocument);
                            }}
                          >
                            Browse
                          </YarsFileUploadButton>
                        </YarsAvatarListItem>
                      ))}
                    </List>
                    <Typography
                      variant="body2"
                      sx={{ marginTop: "1rem", textAlign: "center" }}
                    >
                      Allowed file types: .csv, .doc, .docx, .xls, .xlsx, .pdf,
                      .txt. Maximum file size {MAX_FILE_SIZE_PRETTY}.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Box m={2}>
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item>
                          <Box sx={{ position: "relative" }}>
                            <YarsCardActionButton
                              disabled={!hasUploads() || submitting}
                              onClick={submitDocuments}
                            >
                              Upload
                            </YarsCardActionButton>
                            {submitting && (
                              <CircularProgress
                                size={24}
                                sx={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  marginTop: "-12px",
                                  marginLeft: "-12px",
                                }}
                              />
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} md={12} lg={6} mt={3}>
                <NotesCard<Session> item={session} setItem={setSession} />
              </Grid>
            </Grid>
          </Grid>
        </WithTitle>
      )}
    </>
  );
};

/**
 * Return a date, formatted as a string like "Mmmm DD, YYYY".
 * @returns string
 */
const formatDate = (date: Date) => {
  return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_FULL);
};

/**
 * Return a formatted date string, or "Unknown" if no date is supplied.
 * @returns string
 */
const getFormattedDateString = (date?: Date) => {
  if (date) {
    return formatDate(date);
  } else {
    return "Unknown";
  }
};

function SessionDatesCard(props: { session: Session }) {
  const session = props.session;

  const startDate = getFormattedDateString(session.startDate);
  const endDate = getFormattedDateString(session.endDate);
  const registrationOpen = session.registrationOpen
    ? formatDate(session.registrationOpen)
    : "Unknown";
  const registrationClose = session.registrationClose
    ? formatDate(session.registrationClose)
    : "Unknown";
  const formsDue = session.formsDue ? formatDate(session.formsDue) : "Unknown";
  const formsClose = session.formsClose
    ? formatDate(session.formsClose)
    : "Unknown";

  const sessionFee = session.fee;

  const sessionDeposit = session.deposit;

  return (
    <Card
      role="group"
      variant="outlined"
      aria-label="Dates and fees"
      className="uw-card-details"
    >
      <YarsCardHeader title="Dates & Fees" />
      <CardContent>
        <List dense={true} className="details-list">
          <ListItem aria-labelledby="sessionStartDay">
            <ListItemAvatar>
              <Avatar>
                <EventIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              id="sessionStartDay"
              primary="Start Date"
              secondary={startDate}
            />
          </ListItem>
          <ListItem aria-labelledby="sessionEndDay">
            <ListItemAvatar>
              <Avatar>
                <EventIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              id="sessionEndDate"
              primary="End Date"
              secondary={endDate}
            />
          </ListItem>
          <ListItem aria-labelledby="sessionFirstDayToReg">
            <ListItemAvatar>
              <Avatar>
                <EventIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              id="sessionFirstDayToReg"
              primary="Registration Start Date"
              secondary={registrationOpen}
            />
          </ListItem>
          <ListItem aria-labelledby="sessionLastDayToReg">
            <ListItemAvatar>
              <Avatar>
                <DeadlineIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              id="sessionLastDayToReg"
              primary="Registration Deadline"
              secondary={registrationClose}
            />
          </ListItem>
          <ListItem aria-labelledby="sessionParticipantsSubmissionDue">
            <ListItemAvatar>
              <Avatar>
                <DeadlineIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              id="sessionParticipantsSubmissionDue"
              primary="Participants Forms Submissions Due"
              secondary={formsDue}
            />
          </ListItem>
          <ListItem aria-labelledby="sessionFormsUpdatesDue">
            <ListItemAvatar>
              <Avatar>
                <DeadlineIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              id="sessionFormsUpdatesDue"
              primary="Last Day For Participant Forms Updates"
              secondary={formsClose}
            />
          </ListItem>
          <ListItem aria-labelledby="sessionFee">
            <ListItemAvatar>
              <Avatar>
                <MoneyIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              id="sessionFee"
              primary="Fee"
              secondary={` ${
                sessionFee ? `$${sessionFee}` : "No Fee Amount Given"
              }`}
            />
          </ListItem>
          <ListItem aria-labelledby="sessionDeposit">
            <ListItemAvatar>
              <Avatar>
                <MoneyIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              id="sessionDeposit"
              primary="Deposit"
              secondary={` ${
                sessionDeposit
                  ? `$${sessionDeposit}`
                  : "No Deposit Amount Given"
              }`}
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
}

function SessionDetailsCard(props: { session: Session }) {
  const { user } = useSessionContext();
  const [redirectObj, setRedirectObj] = useState<RedirectType>({
    redirect: false,
    uri: "",
  });
  const { setNotification } = useNotificationContext();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [isDeleting, setDeleting] = useState<boolean>(false);
  const session = props.session;
  const location = useLocation();
  const { programSlug } = useParams<{ programSlug: string }>();

  const disableEdits =
    session.runStatus === "Completed" ||
    session.runStatus === "Canceled" ||
    (isProgram(session.program) && session.program.status === "reviewed");

  function DirectorListItem(props: {
    director: Session["director"];
  }): ReactElement {
    let to = "";
    let disabled = true;
    let text = "Not Assigned";

    if (props.director && isPerson(props.director)) {
      to = `/people/${props.director.slug}`;
      disabled = false;
      text = props.director.fullName;
    }

    return (
      <ListItem component={NavLinkForward} to={to} disabled={disabled} button>
        <ListItemAvatar>
          <Avatar className="avatar-icon">
            <PersonIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Session Director" secondary={text} />
      </ListItem>
    );
  }

  function handleDelete() {
    setDeleting(true);
    const sessionName = session.name;
    const sessionId = session.id;
    sessionClient
      .deleteById(session.id)
      .then(() => {
        setNotification({
          message: `Deleted session ${sessionName ?? ""}.`,
          type: "success",
        });
        setRedirectObj({
          redirect: true,
          uri: "/programs/" + programSlug + "/",
        });
      })
      .catch((e: Error) => {
        console.error(
          `Unable to delete session ${sessionId ?? ""}: ${e.message}`
        );
        setNotification({
          message: `Unable to delete ${sessionName ?? ""}`,
          type: "error",
        });
        setDeleting(false);
      });
  }

  if (redirectObj.redirect) {
    return <Redirect push to={redirectObj.uri} />;
  }

  function openDeleteDialog() {
    setDeleteDialogOpen(true);
  }

  function closeDeleteDialog() {
    setDeleteDialogOpen(false);
  }

  const deleteDialogContent = (
    <>
      Are you sure you would like to delete <strong>{session.name}</strong>?
    </>
  );

  const documents = [
    {
      file: session.youthSupervisionPlan,
      exists: session.hasYouthSupervisionPlan,
      title: "Youth Supervision Plan",
      labelId: "hasYouthSuperivisonPlan",
    },
    {
      file: session.healthAndSafetyPlan,
      exists: session.hasHealthAndSafetyPlan,
      title: "Health & Safety Plan",
      labelId: "hasHealthAndSafetyPlan",
    },
    {
      file: session.emergencyPlan,
      exists: session.hasEmergencyPlan,
      title: "Emergency Plan",
      labelId: "hasEmergencyPlan",
    },
    {
      file: session.escalationMatrix,
      exists: session.hasEscalationMatrix,
      title: "Escalation Matrix",
      labelId: "hasEscalationMatrix",
    },
    {
      file: session.orientationAgenda,
      exists: session.hasOrientationAgenda,
      title: "Orientation Agenda",
      labelId: "hasOrientationAgenda",
    },
    {
      file: session.thirdPartyAgreement,
      exists: session.hasThirdPartyAgreement,
      title: "Third Party Agreement",
      labelId: "hasThirdPartyAgreement",
    },
    {
      file: session.staffRoster,
      exists: session.hasStaffRoster,
      title: "Staff Roster",
      labelId: "hasStaffRoster",
    },
  ];

  return (
    <Card
      role="group"
      variant="outlined"
      aria-label="Session details"
      className="uw-card-details"
    >
      <DeleteDialog
        open={deleteDialogOpen}
        title="Confirm Deletion"
        content={deleteDialogContent}
        handleDelete={handleDelete}
        handleClose={closeDeleteDialog}
        isDeleting={isDeleting}
      />
      <YarsCardHeader title="Details" />
      <CardContent>
        <List dense={true} className="details-list">
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <ClassIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              id="sessionName"
              primary="Name"
              secondary={session.name}
            />
          </ListItem>
          <DirectorListItem director={session.director} />
          <ListItem aria-labelledby="sessionType">
            <ListItemAvatar>
              <Avatar>
                <MapsHomeWorkIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              id="sessionType"
              primary="Type"
              secondary={formattedType(session)}
            />
          </ListItem>
          {documents.map((document) => (
            <YarsAvatarListItem
              avatar={document.exists ? <SaveAltIcon /> : <PriorityIcon />}
              className={document.exists ? "success" : "warning"}
              key={document.title}
            >
              <ListItemText
                id={document.labelId}
                secondary={
                  (document.file && (document.file as Document).name) ||
                  "Not submitted"
                }
              >
                {document.title}
              </ListItemText>
              {user && document.exists && (
                <YarsDownloadButton
                  href={(document.file as Document).downloadUri}
                >
                  Download
                </YarsDownloadButton>
              )}
            </YarsAvatarListItem>
          ))}
        </List>
      </CardContent>
      <CardActions>
        <Box m={2}>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <YarsCardActionButton
                component={NavLinkForward}
                to={`${location.pathname}/edit`}
                disabled={disableEdits}
              >
                Edit Session
              </YarsCardActionButton>
            </Grid>
            <Grid item>
              <Button
                className="secondary-action-btn"
                onClick={openDeleteDialog}
                variant="outlined"
                size="medium"
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </Box>
      </CardActions>
    </Card>
  );
}

const RunStatusListItem = ({ session }: { session: Session }) => {
  return (
    <ListItem>
      <RunStatusAvatar runStatus={session.runStatus} />
      <ListItemText
        secondary={
          <>
            {session.runStatus}
            <br />
            <Typography component="span">
              {getRunStatusDescription(session)}
            </Typography>
          </>
        }
      />
    </ListItem>
  );
};

const RunStatusCard = ({
  session,
  setSession,
}: {
  session: Session;
  setSession: Dispatch<SetStateAction<Session>>;
}) => {
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

  return (
    <>
      <CancelSessionDialog
        session={session}
        open={cancelDialogOpen}
        handleClose={() => setCancelDialogOpen(false)}
        setSession={setSession}
      />
      <Card
        variant="outlined"
        aria-label="Session Status Information"
        className="uw-card-details"
        sx={{ mb: 2 }}
      >
        <YarsCardHeader
          title="Status"
          action={
            <Button
              onClick={() => setCancelDialogOpen(true)}
              disabled={
                ["Completed", "Running"].includes(session.runStatus) ||
                session.canceled
              }
            >
              Cancel Session
            </Button>
          }
        />
        <CardContent>
          <List dense={true} className="details-list">
            <RunStatusListItem session={session} />
          </List>
        </CardContent>
      </Card>
    </>
  );
};

/**
 * Return a fully formatted string representing the type of this session
 * @param session Session
 * @returns string
 */
const formattedType = (session: Session) => {
  if (session.typeCode === "res") {
    return `${session.typeDisplay!}: ${session.primaryOvernightLocation!}`;
  }

  return `${session.typeDisplay!}` || "Unknown";
};

/**
 * Return a fully formatted string representing a run status description.
 * @param session Session
 * @returns string
 */
const getRunStatusDescription = (session: Session) => {
  switch (session.runStatus) {
    case "Canceled": {
      return `This session did not run. It was canceled on ${getFormattedDateString(
        session.canceledOn
      )}.`;
    }
    case "Completed": {
      return `This session occurred in the past. It ran from ${getFormattedDateString(
        session.startDate
      )} to ${getFormattedDateString(session.endDate)}.`;
    }
    case "Pending": {
      return `This is an upcoming session. It will start on ${getFormattedDateString(
        session.startDate
      )}.`;
    }
    case "Running": {
      return `This session is currently active! It began on ${getFormattedDateString(
        session.startDate
      )} and will end on ${getFormattedDateString(session.endDate)}.`;
    }
  }
};

export default SessionDetail;
