import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import PriorityIcon from "@mui/icons-material/PriorityHigh";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { YarsAvatarListItem } from "../../components/list";
import YarsCardHeader from "../../components/YarsCardHeader";
import NavLinkForward from "../../navigation/NavLinkForward";
import { ageRangeItems, Session } from "./../types";

export function getEnv(name: string): string {
  const value = process.env[name.toUpperCase()];
  if (!value) {
    throw new Error(`Environment variable ${name} is not defined`);
  }
  return value;
}

const getAgeRangeText = (ageRangeValue?: string): string | undefined => {
  if (ageRangeValue) {
    return ageRangeItems.find((i) => i.val === ageRangeValue)?.text;
  }
  return "Unknown";
};

const ParticipantCard = (props: { session: Session }) => {
  const location = useLocation();
  const session = props.session;

  return (
    <Card
      variant="outlined"
      aria-label="Particpants details"
      className="uw-card-details"
    >
      <YarsCardHeader title="Participants" />
      <CardContent>
        <List dense={true} className="details-list">
          <ListItem>
            <ListItemAvatar
              className={session.maxParticipants ? "success" : "warning"}
            >
              <Avatar>
                {session.maxParticipants ? <GroupsIcon /> : <PriorityIcon />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Maximum Number of Participants"
              secondary={session.maxParticipants || "Unknown"}
            />
          </ListItem>
          <YarsAvatarListItem avatar={<GroupsIcon />}>
            <ListItemText
              primary="Registered Participants"
              secondary={session.registeredParticipants || "0"}
            />
          </YarsAvatarListItem>
          <ListItem>
            <ListItemAvatar className={session.ageRange ? "info" : "warning"}>
              <Avatar>
                {session.ageRange ? <PersonIcon /> : <PriorityIcon />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Age Range"
              secondary={getAgeRangeText(session.ageRange)}
            />
          </ListItem>
        </List>
      </CardContent>
      <CardActions>
        <Box m={2}>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Button
                className="primary-action-btn"
                component={NavLinkForward}
                to={`${location.pathname}/participants`}
                size="medium"
                variant="contained"
              >
                Manage Participants
              </Button>
            </Grid>
          </Grid>
        </Box>
      </CardActions>
    </Card>
  );
};

export default ParticipantCard;
