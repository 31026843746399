import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiError } from "../api/errors";
import sponsorUnitClient from "../api/sponsorUnitClient";
import ErrorMessage from "../components/ErrorMessage";
import { WithTitle } from "../context/TitleContext";
import SponsorUnitInfoCard from "./SponsorUnitInfoCard";
import { SponsorUnit } from "./types";

function SponsorUnitDetail() {
  const [sponsorUnit, setSponsorUnit] = useState<SponsorUnit>(
    {} as SponsorUnit
  );
  const [apiError, setApiError] = useState<ApiError | null>(null);

  const { sponsorUnitSlug } = useParams<{ sponsorUnitSlug: string }>();

  useEffect(() => {
    if (sponsorUnitSlug) {
      sponsorUnitClient
        .getBySlug(sponsorUnitSlug)
        .then((sponsorUnitResponse) => setSponsorUnit(sponsorUnitResponse))
        .catch((e: ApiError) => {
          setApiError(e);
          console.error(
            `Could not fetch sponsor unit with slug ${sponsorUnitSlug}: ${e.message}`
          );
        });
    }
  }, [sponsorUnitSlug]);

  return (
    <>
      {apiError ? (
        <ErrorMessage message="No Sponsor Unit Found" />
      ) : (
        <WithTitle subtitle={sponsorUnit.name}>
          <SponsorUnitDetailContent sponsorUnit={sponsorUnit} />
        </WithTitle>
      )}
    </>
  );
}

function SponsorUnitDetailContent(props: { sponsorUnit: SponsorUnit }) {
  const sponsorUnit = props.sponsorUnit;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <SponsorUnitInfoCard sponsorUnit={sponsorUnit} />
      </Grid>
    </Grid>
  );
}

export default SponsorUnitDetail;
