import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiError } from "../api/errors";
import personClient from "../api/personClient";
import ErrorMessage from "../components/ErrorMessage";
import YarsCardHeader from "../components/YarsCardHeader";
import { WithTitle } from "../context/TitleContext";
import NavLinkForward from "../navigation/NavLinkForward";
import { Person } from "./types";

function PersonDetail() {
  const [person, setPerson] = useState<Person>({} as Person);
  const [apiError, setApiError] = useState<ApiError | null>(null);

  const { personSlug } = useParams<{ personSlug: string }>();

  useEffect(() => {
    if (personSlug) {
      personClient
        .getBySlug(personSlug)
        .then((personResponse) => setPerson(personResponse))
        .catch((e: ApiError) => {
          setApiError(e);
          console.error(
            `Could not fetch person with slug ${personSlug}: ${e.message}`
          );
        });
    }
  }, [personSlug]);

  return (
    <>
      {apiError ? (
        <ErrorMessage message="No Person Found" />
      ) : (
        <WithTitle subtitle={person.fullName}>
          <PersonDetailContent person={person} />
        </WithTitle>
      )}
    </>
  );
}

function PersonDetailContent(props: { person: Person }) {
  const person = props.person;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <PersonInfoCard person={person} />
      </Grid>
    </Grid>
  );
}

function PersonInfoCard(props: { person: Person }) {
  const person = props.person;

  function cardActions(): ReactNode {
    if (person.slug) {
      return (
        <Button
          className="primary-action-btn"
          component={NavLinkForward}
          to={`/people/${person.slug}/edit`}
          size="medium"
          variant="outlined"
        >
          Edit Person
        </Button>
      );
    }
    return <></>;
  }

  return (
    <Card
      variant="outlined"
      aria-label="Person details"
      className="uw-card-details"
    >
      <YarsCardHeader title="Person Details" />
      <CardContent>
        <List dense={true} className="details-list">
          <ListItem aria-labelledby="personName">
            <ListItemAvatar>
              <Avatar>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              id="personName"
              primary="Full Name"
              secondary={person.fullName}
            />
          </ListItem>
          <ListItem aria-labelledby="personEmail">
            <ListItemAvatar>
              <Avatar>
                <EmailIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              id="personEmail"
              primary="Email"
              secondary={person.email}
            />
          </ListItem>
          <ListItem aria-labelledby="personPhone">
            <ListItemAvatar>
              <Avatar>
                <PhoneIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              id="personPhone"
              primary="Phone Number"
              secondary={person.phoneNumber || "No Phone Number Listed"}
            />
          </ListItem>
        </List>
      </CardContent>
      <CardActions>{cardActions()}</CardActions>
    </Card>
  );
}

export default PersonDetail;
