import { Button, Card, CardContent } from "@mui/material";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import YarsCardHeader from "../components/YarsCardHeader";
import { useSessionContext } from "../context/SessionContext";
import { Program } from "../programs/types";
import SessionsTable from "./SessionsTable";
import { Session } from "./types";

/**
 * Session View Card
 */
function SessionsList(props: { sessions: Session[]; program: Program }) {
  const { sessions, program } = props;

  const session = useSessionContext();

  function cardAction(): ReactNode {
    if (
      props.program.permissions?.includes("manage_program_sessions") ||
      session.user?.permissions.includes("sessions.add_session")
    ) {
      return (
        <CardAction
          aria-label="Create program session"
          createUrl={`/programs/${program.slug}/sessions/create`}
        />
      );
    }
  }

  return (
    <Card
      variant="outlined"
      aria-label="Program sessions table"
      className="uw-card"
    >
      <YarsCardHeader title="Sessions" action={cardAction()} />
      <CardContent className="uw-table-container">
        <SessionsTable sessions={sessions} />
      </CardContent>
    </Card>
  );
}

function CardAction(props: { createUrl: string }) {
  const { createUrl } = props;
  return (
    <Button
      className="primary-action-btn"
      component={Link}
      to={createUrl}
      variant="outlined"
      role="button"
    >
      Create Session
    </Button>
  );
}
export default SessionsList;
