import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-premium";
import { Link } from "react-router-dom";
import YarsTable, { yarsActions } from "../components/YarsTable";
import { isSponsorUnit } from "../sponsor-units/types";
import { statusLabel } from "./ProgramStatus";
import { Program } from "./types";

/**
 * Render a programs table with filtering and searching of the table
 */
const ProgramsTable = ({
  programs,
  isLoading,
}: {
  programs: Array<Program>;
  isLoading: boolean;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  function renderActions(program: Program) {
    const detailsLink = `/programs/${program.slug}`;
    const detailsLinkLabel = `View ${program.name} program details`;
    if (!program.slug) {
      return null;
    }
    return (
      <Link
        to={detailsLink}
        className="table-action-link"
        aria-label={detailsLinkLabel}
      >
        <ReadMoreIcon /> View
      </Link>
    );
  }

  function getSponsorUnitName(params: GridValueGetterParams) {
    const program: Program = params.row as Program;
    return program.sponsorUnit && isSponsorUnit(program.sponsorUnit)
      ? program.sponsorUnit.name
      : "";
  }

  const getStatus = (params: GridValueGetterParams) => {
    return statusLabel((params.row as Program).status);
  };

  const columns: GridColDef[] = [
    { field: "name", headerName: "Program Name", flex: 1 },
    {
      hide: isMobile ? true : false,
      field: "sponsorUnit",
      headerName: "Sponsor Unit",
      valueGetter: getSponsorUnitName,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      valueGetter: getStatus,
    },
    {
      field: "actions",
      renderCell: (params: GridRenderCellParams) => renderActions(params.row),
      ...yarsActions,
    },
  ];

  return (
    <YarsTable
      isLoading={isLoading}
      rows={programs}
      columns={columns}
      ariaLabel="programs table"
    />
  );
};

export default ProgramsTable;
