import {
  Avatar,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { useEffect, useState } from "react";
import reportClient, { CampusProgramsCountReport } from "../api/reportClient";
import Page from "../components/Page";
import YarsCardHeader from "../components/YarsCardHeader";
import { useSessionContext } from "../context/SessionContext";
import ProgramCard from "../programs/ProgramCard";
import ProgramsSubmittedList from "../programs/ProgramsSubmittedList";

const CampusOverviewCard = () => {
  const { user } = useSessionContext();
  const [organizations, setOrganizations] = useState(
    [] as Array<CampusProgramsCountReport>
  );

  useEffect(() => {
    (user?.isSystemDirector || user?.isSuperuser) &&
      reportClient
        .getCampusProgramsCount()
        .then((orgs) => setOrganizations(orgs))
        .catch();
  }, []);

  return (
    <Card
      variant="outlined"
      aria-label="campus program overview dashboard module"
      className="uw-card"
    >
      <YarsCardHeader title="Active Programs Per Campus" component="h2" />
      <CardContent>
        {organizations && (
          <List dense={true} className="details-list">
            {organizations.map((org) => {
              return (
                <ListItem key={`${org.name}-${org.id}`}>
                  <ListItemAvatar>
                    <Avatar>{org.activePrograms}</Avatar>
                  </ListItemAvatar>
                  <ListItemText secondary={org.name}></ListItemText>
                </ListItem>
              );
            })}
          </List>
        )}
      </CardContent>
    </Card>
  );
};

/** A dashboard of useful information and links */
export const Dashboard = () => {
  const { user } = useSessionContext();
  return (
    <Page>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <ProgramCard />
        </Grid>
        {(user?.isOrganizationLiaison || user?.isSuperuser) && (
          <Grid item xs={12} md={6}>
            <ProgramsSubmittedList />
          </Grid>
        )}
        {(user?.isSystemDirector || user?.isSuperuser) && (
          <Grid item xs={12} md={6}>
            <CampusOverviewCard />
          </Grid>
        )}
      </Grid>
    </Page>
  );
};

export default Dashboard;
