/**
 * Provides access to the Sponsor Unit YARS API
 */
import { isPerson, isPersonJson } from "../people/types";
import { SponsorUnit, SponsorUnitJson } from "../sponsor-units/types";
import api from "./api";
import { ApiError } from "./errors";
import personClient from "./personClient";

/**
 * Fetches Sponsor Unit by id
 */
async function getAll(): Promise<Array<SponsorUnit>> {
  const response = await api.call("/sponsor-units/?ordering=name");
  const json = (await response.json()) as Array<SponsorUnitJson>;
  return json.map<SponsorUnit>((value) => unmarshall(value));
}

/**
 * Fetches Sponsor Unit by id
 */
async function getById(id: number): Promise<SponsorUnit> {
  const response = await api.call(`/sponsor-units/${id}/`);
  return unmarshall((await response.json()) as SponsorUnitJson);
}

/**
 * Fetches Sponsor Unit by slug
 */
async function getBySlug(slug: string): Promise<SponsorUnit> {
  const response = await api.call("/sponsor-units/?slug=" + slug);
  return unmarshall(((await response.json()) as SponsorUnitJson[])[0]);
}

/**
 * Update a sponsor unit.
 */
async function update(sponsorUnit: Partial<SponsorUnit>): Promise<SponsorUnit> {
  if (!sponsorUnit.id) {
    throw new ApiError("sponsorUnit.id is missing");
  }

  // Enforce RES person to be an integer
  if (sponsorUnit.resPerson && isPerson(sponsorUnit.resPerson)) {
    sponsorUnit.resPerson = sponsorUnit.resPerson.id;
  }

  const response = await api.call(`/sponsor-units/${sponsorUnit.id}/`, {
    method: "PUT",
    body: JSON.stringify(marshall(sponsorUnit)),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return unmarshall((await response.json()) as SponsorUnitJson);
}

/**
 * Delete the sponsor unit by ID.
 */
async function deleteById(id: number): Promise<void> {
  await api.call(`/sponsor-units/${id}/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

/**
 * Create a new sponsor unit
 */
async function create(sponsorUnit: Partial<SponsorUnit>): Promise<SponsorUnit> {
  // Enforce RES person to be an integer
  if (sponsorUnit.resPerson && isPerson(sponsorUnit.resPerson)) {
    sponsorUnit.resPerson = sponsorUnit.resPerson.id;
  }

  const response = await api.call("/sponsor-units/", {
    method: "POST",
    body: JSON.stringify(marshall(sponsorUnit)),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return unmarshall((await response.json()) as SponsorUnitJson);
}

function marshall(sponsorUnit: Partial<SponsorUnit>): Partial<SponsorUnitJson> {
  return {
    id: sponsorUnit.id,
    name: sponsorUnit.name,
    slug: sponsorUnit.slug,
    res_person:
      sponsorUnit.resPerson && isPerson(sponsorUnit.resPerson)
        ? sponsorUnit.resPerson.id
        : sponsorUnit.resPerson,
    created_at: sponsorUnit.createdAt
      ? sponsorUnit.createdAt.toISOString()
      : undefined,
    updated_at: sponsorUnit.updatedAt
      ? sponsorUnit.updatedAt.toISOString()
      : undefined,
  };
}

function unmarshall(json: SponsorUnitJson): SponsorUnit {
  return {
    id: json.id,
    name: json.name,
    slug: json.slug,
    resPerson:
      json.res_person && isPersonJson(json.res_person)
        ? personClient.unmarshall(json.res_person)
        : json.res_person,
    createdAt: json.created_at ? new Date(json.created_at) : undefined,
    updatedAt: json.updated_at ? new Date(json.updated_at) : undefined,
  };
}

const sponsorUnitClient = {
  getAll: getAll,
  create: create,
  getById: getById,
  getBySlug: getBySlug,
  update: update,
  deleteById: deleteById,
  marshall: marshall,
  unmarshall: unmarshall,
};

export default sponsorUnitClient;
