/**
 * Provides access to the Sessions API
 */
import { DateTime } from "luxon";
import { isPerson, isPersonJson } from "../people/types";
import { isProgram, isProgramJson } from "../programs/types";
import { RunStatus, Session, SessionJson } from "../sessions/types";
import { api } from "./api";
import documentClient, { isDocument, isDocumentJson } from "./documentClient";
import { ApiError } from "./errors";
import personClient from "./personClient";
import programClient from "./programClient";
import { marshalJSDate, marshalNumber, unmarshalJSDate } from "./utils";

class SessionClient {
  private metadata: any;

  /**
   * Returns metadata about the session model.
   */
  public async getMetadata() {
    if (!this.metadata) {
      const response = await api.call("/sessions/", { method: "OPTIONS" });
      this.metadata = response.json();
    }
    return this.metadata as Promise<object>;
  }

  /**
   * Fetches session by id
   */
  public async getById(id: number): Promise<Session> {
    const response = await api.call(`/sessions/${id}/`);
    return this.unmarshall((await response.json()) as SessionJson);
  }

  /**
   * Fetches all of the users available sessions
   */
  public async getAll(): Promise<Array<Session>> {
    const response = await api.call("/sessions/");
    const json = (await response.json()) as Array<SessionJson>;
    return json.map((sessionJson) => this.unmarshall(sessionJson));
  }

  /**
   * Fetches all of the users available sessions
   */
  public async getByCreatedAt(createdAt: string): Promise<Array<Session>> {
    const response = await api.call("/sessions/?created_at=" + createdAt);
    const json = (await response.json()) as Array<SessionJson>;
    return json.map((sessionJson) => this.unmarshall(sessionJson));
  }

  /**
   * Fetches session by slug
   */
  public async getBySlug(slug: string): Promise<Session> {
    const response = await api.call("/sessions/?slug=" + slug);
    return this.unmarshall(((await response.json()) as SessionJson[])[0]);
  }

  /**
   * Updates a session
   */
  public async update(session: Partial<Session>): Promise<Session> {
    session = this.normalizeForeignKeys(session);
    if (!session.id) {
      throw new ApiError("session.id is required");
    }

    // Enforce director to be integer
    if (session.director && isPerson(session.director)) {
      session.director = session.director.id;
    }

    const fetchInit = {
      method: "PUT",
      body: JSON.stringify(this.marshall(session)),
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await api.call(`/sessions/${session.id}/`, fetchInit);
    const json = (await response.json()) as SessionJson;
    return this.unmarshall(json);
  }

  /**
   * Create a session
   */
  async create(session: Partial<Session>): Promise<Session> {
    session = this.normalizeForeignKeys(session);
    const fetchBody = {
      method: "POST",
      body: JSON.stringify(this.marshall(session)),
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await api.call("/sessions/", fetchBody);
    const json = (await response.json()) as SessionJson;
    return this.unmarshall(json);
  }

  /**
   * Deletes a session
   */
  async deleteById(id: number): Promise<void> {
    const fetchBody = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    };
    await api.call(`/sessions/${id}/`, fetchBody);
  }

  /**
   * Take a session and change the objects to the pk of that object. This allows
   * for saving relationships on the session object.
   */
  public normalizeForeignKeys(session: Partial<Session>) {
    if (session.director && isPerson(session.director)) {
      session.director = session.director.id;
    }
    if (session.program && isProgram(session.program)) {
      session.program = session.program.id;
    }
    return session;
  }

  public marshall(session: Partial<Session>): Partial<SessionJson> {
    const director = session.director;
    const program = session.program;
    const youthSupervisionPlan = session.youthSupervisionPlan;
    const healthAndSafetyPlan = session.healthAndSafetyPlan;
    const emergencyPlan = session.emergencyPlan;
    const escalationMatrix = session.escalationMatrix;
    const orientationAgenda = session.orientationAgenda;
    const thirdPartyAgreement = session.thirdPartyAgreement;
    const staffRoster = session.staffRoster;
    return {
      id: session.id,
      name: session.name,
      description: session.description,
      notes: session.notes,
      slug: session.slug,
      director: director && isPerson(director) ? director.id : director,
      program: program && isProgram(program) ? program.id : program,
      date_confirmed: session.dateConfirmed,
      primary_location: session.primaryLocation,
      primary_overnight_location: session.primaryOvernightLocation,
      age_range: session.ageRange,
      max_participants: session.maxParticipants,
      registered_participants: session.registeredParticipants,
      waitlist: session.waitlist,
      approval_status: session.approvalStatus,
      approval_source: session.approvalSource,
      approval_type: session.approvalType,
      type_code: session.typeCode,
      type_display: session.typeDisplay,
      type_info: session.typeInfo,
      approval_notes: session.approvalNotes,
      custodial_care: session.custodialCare,
      custodial_care_display: session.custodialCareDisplay,
      registered_student_org_type: session.registeredStudentOrgType,
      start_date: session.startDate?.toISOString(),
      end_date: session.endDate?.toISOString(),
      run_status: session.runStatus,
      canceled: session.canceled,
      canceled_on: session.canceledOn
        ? marshalJSDate(session.canceledOn)
        : undefined,
      exclude_from_integrations: session.excludeFromIntegrations,
      participant_count: session.participantCount,
      staff_count: session.staffCount,
      staff_background_checks: session.staffBackgroundChecks,
      staff_required_training: session.staffRequiredTraining,
      staff_orientation: session.staffOrientation,
      staff_youngest_age: session.staffYoungestAge,
      youth_supervision_plan:
        youthSupervisionPlan && isDocument(youthSupervisionPlan)
          ? youthSupervisionPlan.id
          : youthSupervisionPlan,
      health_and_safety_plan:
        healthAndSafetyPlan && isDocument(healthAndSafetyPlan)
          ? healthAndSafetyPlan.id
          : healthAndSafetyPlan,
      emergency_plan:
        emergencyPlan && isDocument(emergencyPlan)
          ? emergencyPlan.id
          : emergencyPlan,
      escalation_matrix:
        escalationMatrix && isDocument(escalationMatrix)
          ? escalationMatrix.id
          : escalationMatrix,
      orientation_agenda:
        orientationAgenda && isDocument(orientationAgenda)
          ? orientationAgenda.id
          : orientationAgenda,
      third_party_agreement:
        thirdPartyAgreement && isDocument(thirdPartyAgreement)
          ? thirdPartyAgreement.id
          : thirdPartyAgreement,
      staff_roster:
        staffRoster && isDocument(staffRoster) ? staffRoster.id : staffRoster,
      created_at: session.createdAt?.toISOString(),
      updated_at: session.updatedAt?.toISOString(),
      forms_due: session.formsDue ? marshalJSDate(session.formsDue) : undefined,
      forms_close: session.formsClose
        ? marshalJSDate(session.formsClose)
        : undefined,
      registration_open: session.registrationOpen
        ? marshalJSDate(session.registrationOpen)
        : undefined,
      registration_close: session.registrationClose
        ? marshalJSDate(session.registrationClose)
        : undefined,
      fee: marshalNumber(session.fee),
      deposit: marshalNumber(session.deposit),
      campus_admin_fee: marshalNumber(session.campusAdminFee),
      grant_funded: session.grantFunded,
      upward_bound_trio: session.upwardBoundTrio,
      dpi_scholarship: session.dpiScholarship,
      athletic_activity: session.athleticActivity,
      music_activity: session.musicActivity,
      water_activity: session.waterActivity,
      in_state_travel: session.inStateTravel,
      out_state_travel: session.outStateTravel,
      for_credit: session.forCredit,
      league: session.league,
      gl_code: session.glCode,
      has_volunteers: session.hasVolunteers,
      volunteer_supervisor:
        session.hasVolunteers === "y" ? session.volunteerSupervisor : "",
      volunteer_supervisor_email:
        session.hasVolunteers === "y" ? session.volunteerSupervisorEmail : "",
    };
  }

  /** Unmarshalls a JSON representation of a program into a Program object */
  public unmarshall(json: SessionJson): Session {
    return {
      id: json.id,
      name: json.name,
      description: json.description,
      notes: json.notes,
      slug: json.slug,
      director:
        json.director && isPersonJson(json.director)
          ? personClient.unmarshall(json.director)
          : json.director,
      program:
        json.program && isProgramJson(json.program)
          ? programClient.unmarshall(json.program)
          : json.program,
      dateConfirmed: json.date_confirmed,
      primaryLocation: json.primary_location,
      primaryOvernightLocation: json.primary_overnight_location,
      ageRange: json.age_range,
      maxParticipants: json.max_participants,
      registeredParticipants: json.registered_participants,
      waitlist: json.waitlist,
      approvalStatus: json.approval_status,
      approvalSource: json.approval_source,
      approvalType: json.approval_type,
      approvalNotes: json.approval_notes,
      custodialCare: json.custodial_care,
      custodialCareDisplay: json.custodial_care_display,
      youthSupervisionPlan:
        json.youth_supervision_plan &&
        isDocumentJson(json.youth_supervision_plan)
          ? documentClient.unmarshal(json.youth_supervision_plan)
          : json.youth_supervision_plan,
      healthAndSafetyPlan:
        json.health_and_safety_plan &&
        isDocumentJson(json.health_and_safety_plan)
          ? documentClient.unmarshal(json.health_and_safety_plan)
          : json.health_and_safety_plan,
      emergencyPlan:
        json.emergency_plan && isDocumentJson(json.emergency_plan)
          ? documentClient.unmarshal(json.emergency_plan)
          : json.emergency_plan,
      escalationMatrix:
        json.escalation_matrix && isDocumentJson(json.escalation_matrix)
          ? documentClient.unmarshal(json.escalation_matrix)
          : json.escalation_matrix,
      orientationAgenda:
        json.orientation_agenda && isDocumentJson(json.orientation_agenda)
          ? documentClient.unmarshal(json.orientation_agenda)
          : json.orientation_agenda,
      thirdPartyAgreement:
        json.third_party_agreement && isDocumentJson(json.third_party_agreement)
          ? documentClient.unmarshal(json.third_party_agreement)
          : json.third_party_agreement,
      staffRoster:
        json.staff_roster && isDocumentJson(json.staff_roster)
          ? documentClient.unmarshal(json.staff_roster)
          : json.staff_roster,
      typeCode: json.type_code,
      typeDisplay: json.type_display,
      registeredStudentOrgType: json.registered_student_org_type,
      typeInfo: json.type_info,
      startDate: json.start_date
        ? DateTime.fromISO(json.start_date).toJSDate()
        : undefined,
      endDate: json.end_date
        ? DateTime.fromISO(json.end_date).toJSDate()
        : undefined,
      excludeFromIntegrations: json.exclude_from_integrations,
      participantCount: json.participant_count,
      staffCount: json.staff_count,
      runStatus: json.run_status as RunStatus,
      canceled: json.canceled,
      canceledOn: json.canceled_on
        ? unmarshalJSDate(json.canceled_on)
        : undefined,
      staffBackgroundChecks: json.staff_background_checks,
      staffRequiredTraining: json.staff_required_training,
      staffOrientation: json.staff_orientation,
      staffYoungestAge: json.staff_youngest_age,
      isCompliant: json.is_compliant,
      createdAt: json.created_at ? new Date(json.created_at) : undefined,
      updatedAt: json.updated_at ? new Date(json.updated_at) : undefined,
      formsDue: json.forms_due ? unmarshalJSDate(json.forms_due) : undefined,
      formsClose: json.forms_close
        ? unmarshalJSDate(json.forms_close)
        : undefined,
      registrationOpen: json.registration_open
        ? unmarshalJSDate(json.registration_open)
        : undefined,
      registrationClose: json.registration_close
        ? unmarshalJSDate(json.registration_close)
        : undefined,
      fee: json.fee,
      deposit: json.deposit,
      hasYouthSupervisionPlan: json.has_youth_supervision_plan,
      hasHealthAndSafetyPlan: json.has_health_and_safety_plan,
      hasEmergencyPlan: json.has_emergency_plan,
      hasEscalationMatrix: json.has_escalation_matrix,
      hasOrientationAgenda: json.has_orientation_agenda,
      hasThirdPartyAgreement: json.has_third_party_agreement,
      hasStaffRoster: json.has_staff_roster,
      campusAdminFee: json.campus_admin_fee,
      grantFunded: json.grant_funded,
      upwardBoundTrio: json.upward_bound_trio,
      dpiScholarship: json.dpi_scholarship,
      athleticActivity: json.athletic_activity,
      musicActivity: json.music_activity,
      waterActivity: json.water_activity,
      inStateTravel: json.in_state_travel,
      outStateTravel: json.out_state_travel,
      forCredit: json.for_credit,
      league: json.league,
      hasVolunteers: json.has_volunteers,
      volunteerSupervisor: json.volunteer_supervisor,
      volunteerSupervisorEmail: json.volunteer_supervisor_email,
      glCode: json.gl_code,
    };
  }
}

const sessionClient = new SessionClient();
export default sessionClient;
