import RegistrationIcon from "@mui/icons-material/Assignment";
import InfoIcon from "@mui/icons-material/Info";
import WebsiteIcon from "@mui/icons-material/LaptopMac";
import UpdateIcon from "@mui/icons-material/Update";
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { DateTime } from "luxon";
import { ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiError } from "../api/errors";
import programClient from "../api/programClient";
import ManageGroupCard from "../auth/ManageGroupCard";
import { YarsCardActionButton } from "../components/button";
import ErrorMessage from "../components/ErrorMessage";
import NotesCard from "../components/NotesCard";
import YarsCardHeader from "../components/YarsCardHeader";
import { useNotificationContext } from "../context/NotificationContext";
import { WithTitle } from "../context/TitleContext";
import NavLinkForward from "../navigation/NavLinkForward";
import ProgramStatus from "../programs/ProgramStatus";
import SessionsList from "../sessions/SessionsList";
import SponsorUnitInfoCard from "../sponsor-units/SponsorUnitInfoCard";
import { isSponsorUnit } from "../sponsor-units/types";
import ProgramDenialDialog from "./ProgramDenialDialog";
import { Program, Status } from "./types";

function ProgramDetail() {
  const [program, setProgram] = useState<Program>({} as Program);
  const [isUpdating, setIsUpdating] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [apiError, setApiError] = useState<ApiError | null>(null);
  const { setNotification } = useNotificationContext();
  const { programSlug } = useParams<{ programSlug: string }>();
  const sponsorUnit = program.sponsorUnit;

  const updateStatus = (program: Program, status: Status) => {
    setIsUpdating(true);
    programClient
      .update({
        id: program.id,
        name: program.name,
        description: program.description,
        status: status,
      })
      .then((program) => {
        const { status, updatedAt } = program;
        setProgram((initial) => ({ ...initial, status, updatedAt }));
        setNotification({
          message: `Program ${program.status}`,
        });
        setIsUpdating(false);
      })
      .catch((e) => {
        setApiError(e);
        setIsUpdating(false);
      });
  };

  const submitProgram = (program: Program) => {
    updateStatus(program, "submitted");
  };

  const reviewProgram = (program: Program) => {
    updateStatus(program, "reviewed");
  };

  useEffect(() => {
    if (programSlug) {
      programClient
        .getBySlug(programSlug)
        .then((programResponse) => setProgram(programResponse))
        .catch((e: ApiError) => setApiError(e));
    }
  }, [programSlug]);

  return (
    <>
      {apiError ? (
        <ErrorMessage message="No Program Found" />
      ) : (
        <WithTitle subtitle={program.name}>
          <ProgramDenialDialog
            program={program}
            open={dialogOpen}
            handleClose={() => setDialogOpen(false)}
            afterSubmit={() => {
              setIsUpdating(false);
              setDialogOpen(false);
            }}
            setProgram={setProgram}
          />

          <Grid container spacing={3} sx={{ marginBottom: "1rem" }}>
            <Grid item xs={12}>
              <Card
                variant="outlined"
                aria-label="Program description"
                className={`uw-card-details`}
              >
                <YarsCardHeader title="Description" />
                <CardContent>
                  <List dense={true} className="details-list">
                    <ListItem aria-labelledby="programDescription">
                      <ListItemAvatar>
                        <Avatar>
                          <InfoIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        id="programDescription"
                        primary={program.description}
                      />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <ProgramStatus
                program={program}
                onSubmit={() => submitProgram(program)}
                onReview={() => reviewProgram(program)}
                onDeny={() => setDialogOpen(true)}
                isUpdating={isUpdating}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ProgramDetailsCard program={program} />
            </Grid>
            <Grid item xs={12} md={6}>
              <SponsorUnitInfoCard
                program={program}
                sponsorUnit={
                  sponsorUnit && isSponsorUnit(sponsorUnit)
                    ? sponsorUnit
                    : undefined
                }
              />
            </Grid>
            <Grid item xs={12} md={21}>
              <NotesCard<Program> item={program} setItem={setProgram} />
            </Grid>
            {program.sessions && (
              <Grid item xs={12} md={12}>
                <SessionsList sessions={program.sessions} program={program} />
              </Grid>
            )}
            {program.permissions?.includes("assign_program_permission") && (
              <>
                {program.groups?.map((group) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      key={group.id}
                      className="uw-table-container"
                    >
                      <ManageGroupCard group={group} />
                    </Grid>
                  );
                })}
              </>
            )}
          </Grid>
        </WithTitle>
      )}
    </>
  );
}

function ProgramDetailsCard(props: { program: Program }) {
  const program = props.program;

  function cardActions(): ReactNode {
    if (program.permissions?.includes("change_program")) {
      return (
        <YarsCardActionButton
          component={NavLinkForward}
          to={`/programs/${program.slug}/edit`}
          disabled={program.status == "reviewed"}
        >
          Edit Program
        </YarsCardActionButton>
      );
    }
    return <></>;
  }

  return (
    <Card
      variant="outlined"
      aria-label="Program status"
      className={`uw-card-details`}
    >
      <YarsCardHeader title="Program Details" />
      <CardContent>
        <List dense={true} className="details-list">
          <ListItem aria-labelledby="programWebsite">
            <ListItemAvatar>
              <Avatar>
                <WebsiteIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              id="programWebsite"
              primary="Website"
              secondary={program.website ? program.website : "Not set"}
            />
          </ListItem>
          <ListItem aria-labelledby="participantRegistrationSystem">
            <ListItemAvatar>
              <Avatar>
                <RegistrationIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              id="participantRegistrationSystem"
              primary="Participant Registration System"
              secondary={program.participantRegistrationSystem}
            />
          </ListItem>
          <ListItem aria-labelledby="programUpdatedAt">
            <ListItemAvatar>
              <Avatar>
                <UpdateIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              id="programUpdatedAt"
              primary="Last Updated"
              secondary={
                program.updatedAt
                  ? DateTime.fromJSDate(program.updatedAt).toLocaleString(
                      DateTime.DATETIME_MED
                    )
                  : "Never updated"
              }
            />
          </ListItem>
        </List>
      </CardContent>
      <CardActions>
        <Box m={2}>{cardActions()}</Box>
      </CardActions>
    </Card>
  );
}

export default ProgramDetail;
