import { api } from "./api";

export type Document = {
  id: number;
  name: string;
  type: string;
  downloadUri: string;
  createdAt: string;
};

export type DocumentJson = {
  id: number;
  name: string;
  type: string;
  download_uri: string;
  created_at: string;
};

export const isDocument = (
  document: Document | number
): document is Document => {
  return (document as Document).id !== undefined;
};

export const isDocumentJson = (
  document: DocumentJson | number
): document is DocumentJson => {
  return (document as DocumentJson).id !== undefined;
};

export type DocumentType = "ys" | "hs" | "em" | "es" | "oa" | "3a" | "sr";

export interface DocumentTypeMap {
  [name: string]: string;
}

export const typeMap: DocumentTypeMap = {
  youthSupervisionPlan: "ys",
  healthAndSafetyPlan: "hs",
  emergencyPlan: "em",
  escalationMatrix: "es",
  orientationAgenda: "oa",
  thirdPartyAgreement: "3a",
  staffRoster: "sr",
};

/**
 * Create a new Document
 */
const create = async (
  file: File,
  sessionId: number,
  type: DocumentType
): Promise<Document> => {
  const data = new FormData();
  data.append("file", file);
  data.append("session", sessionId.toString());
  data.append("type", type);

  const fetchBody = {
    method: "POST",
    body: data,
  };
  const response = await api.call(`/documents/`, fetchBody, true);
  const json = (await response.json()) as DocumentJson;
  return unmarshal(json);
};

/**
 * Unmarshal JSON from the Documents API
 */
const unmarshal = (json: DocumentJson): Document => {
  return {
    id: json.id,
    name: json.name,
    type: json.type,
    downloadUri: json.download_uri,
    createdAt: json.created_at,
  };
};

const documentClient = {
  create: create,
  unmarshal: unmarshal,
};

export default documentClient;
