import { Typography } from "@mui/material";
import { useState } from "react";
import { Redirect } from "react-router";
import { object, string } from "yup";
import { Redirect as RedirectType } from "../common/types";
import FormGenerator, { Field, Group } from "../components/forms/FormGenerator";
import {
  CloseDialogButton,
  YarsDialog,
  YarsDialogContent,
  YarsDialogTitle,
} from "../components/YarsDialog";
import { Program } from "../programs/types";
import { Session } from "./types";

export const DuplicateSessionDialog = ({
  session,
  open,
  handleClose,
}: {
  session: Session;
  open: boolean;
  handleClose: () => void;
}) => {
  const handleSubmit = (session: Partial<Session>) => {
    // Strip id from current session and use the remaining fields to populate the
    // new session
    const program = session.program as Program;

    const { id, slug, ...rest } = session;
    setRedirectObj({
      uri: `/programs/${program.slug}/sessions/create`,
      redirect: true,
      state: rest,
    });
  };
  const [redirectObj, setRedirectObj] = useState<RedirectType>({
    redirect: false,
    uri: "",
  });

  if (redirectObj.redirect) {
    return (
      <Redirect
        push
        to={{ pathname: redirectObj.uri, state: redirectObj.state }}
      />
    );
  }

  const validationSchema = object().shape({
    name: string().trim().required("Name is required"),
  });
  const fields: Field[] = [
    {
      name: "name",
      prettyName: "Name",
      type: "text",
      required: true,
      ariaLabel: "Name",
    },
  ];

  const groups: Group[] = [
    {
      name: "",
      prettyName: "Duplicate Session",
      fields: fields,
    },
  ];

  const schema = {
    handleSubmit: handleSubmit,
    validationSchema: validationSchema,
    initialValues: session,
    groups: groups,
    saveText: "Submit",
    handleCancel: handleClose,
  };

  return (
    <YarsDialog
      open={open}
      aria-labelledby="duplicate-session-dialog-title"
      aria-describedby="duplicate-session-dialog-content"
    >
      <YarsDialogTitle>
        Duplicate Session
        <CloseDialogButton onClose={handleClose} />
      </YarsDialogTitle>
      <YarsDialogContent>
        <Typography sx={{ marginBottom: "1.5rem" }}>
          Please choose a name that distinctly identifies the new session. After
          submitting, you will be redirected to a new page where you may edit
          the new session&apos;s details.
        </Typography>
        <FormGenerator schema={schema} />
      </YarsDialogContent>
    </YarsDialog>
  );
};
